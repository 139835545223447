/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { IUiState, UiMutations, UiActions, UiGetters } from "@/definitions";
import { Locales } from "@/utils/locale";
import { moment } from "@/utils/datetimeCommon";

const state: IUiState = {
  isLoading: false,
  locale: "",
};

const mutations: MutationTree<IUiState> = {
  [UiMutations.SET_IS_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  [UiMutations.SET_LOCALE](state, locale: string) {
    state.locale = locale;
  },
};

const actions: ActionTree<IUiState, any> = {
  [UiActions.SET_IS_LOADING]({ commit }, isLoading: boolean) {
    commit(UiMutations.SET_IS_LOADING, isLoading);
  },
  [UiActions.SET_LOCALE]({ state, commit }, locale: string) {
    let newLocale = Locales.ptBR;
    if (state.locale === "" && locale === "") {
      locale = localStorage.getItem("locale") ?? Locales.ptBR;
    }

    switch (locale) {
      case Locales.enUS:
      case "en-US":
        moment.locale("en");
        newLocale = Locales.enUS;
        break;
      default:
      case "pt-BR":
        moment.locale("pt-br");
        newLocale = Locales.ptBR;
        break;
    }

    localStorage.setItem("locale", newLocale);
    commit(UiMutations.SET_LOCALE, newLocale);
  },
};

const getters: GetterTree<IUiState, any> = {
  [UiGetters.IS_LOADING](state): boolean {
    return state.isLoading;
  },
  [UiGetters.CURRENT_LOCALE](state): string {
    return state.locale;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
