import { IContactCenterReportServiceGetInfoResult } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class ContactCenterPerAgentReportService extends BaseService<IContactCenterReportServiceGetInfoResult> {
  getInfo(config?: AxiosRequestConfig) {
    return this.http.get<IContactCenterReportServiceGetInfoResult>(
      `${this.route}`,
      config,
    );
  }
}

export default new ContactCenterPerAgentReportService(
  "reports/contact-center-per-agent",
);
