<template>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <slot name="info"></slot>
    </div>
    <div class="mt-4 sm:mt-0 sm:ml-16 flex items-center">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
