import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import ConnectFlowPage from "@/views/pages/connect-flow/ConnectFlowPage.vue";
import ConnectFlowDetailPage from "@/views/pages/connect-flow/ConnectFlowDetailPage.vue";
import ConnectFlowFormPage from "@/views/pages/connect-flow/ConnectFlowFormPage.vue";
import ConnectFlowFlowPage from "@/views/pages/connect-flow/ConnectFlowModelingPage.vue";
import ConnectFlowHistoryPage from "@/views/pages/connect-flow/ConnectFlowHistoryPage.vue";
import { FormAction } from "@/definitions";

const connectFlowRouter: RouteRecordRaw = {
  name: "ConnectFlow",
  path: "connect-flow",
  children: [
    {
      name: "ListConnectFlow",
      path: "",
      component: ConnectFlowPage,
    },
    {
      name: "ViewConnectFlowById",
      path: ":id",
      component: ConnectFlowDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditConnectFlowById",
      path: ":id/edit",
      component: ConnectFlowFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateConnectFlow",
      path: "create",
      component: ConnectFlowFormPage,
      props: { action: FormAction.CREATE },
    },
    {
      name: "ModelingConnectFlow",
      path: ":id/modeling",
      component: ConnectFlowFlowPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "ModelingConnectFlowHistory",
      path: ":id/modeling/:historyId/history",
      component: ConnectFlowFlowPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "HistoryConnectFlow",
      path: ":id/history",
      component: ConnectFlowHistoryPage,
      beforeEnter: [idIsPositiveInteger],
    },
  ],
};

export default connectFlowRouter;
