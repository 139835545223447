import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import NumberPage from "@/views/pages/number/NumberPage.vue";
import NumberDetailPage from "@/views/pages/number/NumberDetailPage.vue";
import NumberFormPage from "@/views/pages/number/NumberFormPage.vue";
import { FormAction } from "@/definitions";

const numberRouter: RouteRecordRaw = {
  name: "Number",
  path: "number",
  children: [
    {
      name: "ListNumber",
      path: "",
      component: NumberPage,
    },
    {
      name: "ViewNumberById",
      path: ":id",
      component: NumberDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditNumberById",
      path: ":id/edit",
      component: NumberFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateNumber",
      path: "create",
      component: NumberFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};

export default numberRouter;
