import { IListable, IMultiSelectOption } from "@/definitions";

export interface IWordFilter {
  callParticipant: IMultiSelectOption | null;
  isActive: IMultiSelectOption | null;
}

export interface IWordState extends IListable {
  filter: IWordFilter;
}

export enum WordMutations {
  SET_SEARCH = "word_setSearch",
  SET_SORT = "word_setSort",
  SET_PAGE = "word_setPage",
  SET_FILTER = "word_filter_setFilter",
  CLEAR_FILTER = "word_filter_clearFilter",
}

export enum WordActions {
  SET_SEARCH = "word_setSearch",
  SET_SORT = "word_setSort",
  SET_PAGE = "word_setPage",
  SET_FILTER = "word_filter_SetFilter",
  CLEAR_FILTER = "word_filter_ClearFilter",
}

export enum WordGetters {
  SEARCH = "word_search",
  SORT = "word_sort",
  PAGE = "word_page",
  DEFAULT_FILTER = "word_default_filter",
  FILTER = "word_filter",
  ACTIVE_FILTERS = "word_active_filters",
}
