import { IContactCenterPerDayReportServiceGetInfoResult } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class ContactCenterPerDayReportService extends BaseService<IContactCenterPerDayReportServiceGetInfoResult> {
  getInfo(config?: AxiosRequestConfig) {
    return this.http.get<IContactCenterPerDayReportServiceGetInfoResult>(
      `${this.route}`,
      config,
    );
  }
}

export default new ContactCenterPerDayReportService(
  "reports/contact-center-per-day",
);
