import { ICall, ICallTimelineItem, IWordsByPersona } from "@/definitions";

export interface ICallPlayerState {
  call: ICall | null;
  timeline: ICallTimelineItem[];
  words: IWordsByPersona;
}

export enum CallPlayerMutations {
  SET_CALL = "call_player_set_call",
  SET_TIMELINE = "call_player_set_timeline",
  SET_WORDS = "call_player_set_words",
}

export enum CallPlayerActions {}

export enum CallPlayerGetters {
  CALL = "call_player_call",
  TIMELINE = "call_player_timeline",
  WORDS = "call_player_words",
}
