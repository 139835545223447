/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  ILevelState,
  LevelMutations,
  LevelActions,
  LevelGetters,
  ILevelFilter,
  ISortMenuItem,
} from "@/definitions";
const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      isActive: null,
    },
  } as ILevelState;
};

const state: ILevelState = getDefaultState();

const mutations: MutationTree<ILevelState> = {
  [LevelMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [LevelMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [LevelMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [LevelMutations.SET_FILTER](state, filter: ILevelFilter) {
    state.filter.isActive = filter.isActive;
  },
  [LevelMutations.CLEAR_FILTER](state, field?: string) {
    // This makes no sense but ok...
    switch (field) {
      case "isActive":
        state.filter.isActive = getDefaultState().filter.isActive;
        break;
      default:
        state.filter.isActive = getDefaultState().filter.isActive;
    }
  },
};

const actions: ActionTree<ILevelState, any> = {
  [LevelActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(LevelMutations.SET_SEARCH, search),
  [LevelActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(LevelMutations.SET_SORT, sort),
  [LevelActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(LevelMutations.SET_PAGE, page),
  [LevelActions.SET_FILTER]: ({ commit }, filter: ILevelFilter) =>
    commit(LevelMutations.SET_FILTER, filter),
  [LevelActions.CLEAR_FILTER]: ({ commit }, filter: ILevelFilter) =>
    commit(LevelMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<ILevelState, any> = {
  [LevelGetters.SEARCH]: (state): string | null => state.search,
  [LevelGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [LevelGetters.PAGE]: (state): number => state.page,
  [LevelGetters.FILTER]: (state): ILevelFilter => state.filter,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
