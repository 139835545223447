import {
  IIntegrationFormState,
  IIntegration,
  IIntegrationLog,
} from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class IntegrationService extends BaseService<
  IIntegration | IIntegrationFormState
> {
  getLogs<ResponseT = IIntegrationLog[]>(
    id: number,
    config?: AxiosRequestConfig,
  ) {
    return this.http.get<ResponseT>(`${this.route}/${id}/logs`, config);
  }
}

export default new IntegrationService("integrations");
