import { IListable } from "@/definitions";

export interface IQueueFilter {
  isActive: boolean | null;
}
export interface IQueueState extends IListable {
  filter: IQueueFilter;
}

export enum QueueMutations {
  SET_SEARCH = "queue_setSearch",
  SET_SORT = "queue_setSort",
  SET_PAGE = "queue_setPage",
  SET_FILTER = "queue_setFilter",
  CLEAR_FILTER = "queue_clearFilter",
}

export enum QueueActions {
  SET_SEARCH = "queue_setSearch",
  SET_SORT = "queue_setSort",
  SET_PAGE = "queue_setPage",
  SET_FILTER = "queue_setFilter",
  CLEAR_FILTER = "queue_clearFilter",
}

export enum QueueGetters {
  SEARCH = "queue_search",
  SORT = "queue_sort",
  PAGE = "queue_page",
  FILTER = "queue_filter",
}
