import MultiCanvas from "wavesurfer.js/src/drawer.multicanvas";
import store from "@/store";
import { CallPlayerGetters, CallType } from "@/definitions";

MultiCanvas.prototype.drawBars = function (peaks, channelIndex, start, end) {
  return this.prepareDraw(
    peaks,
    channelIndex,
    start,
    end,
    ({ absmax, hasMinVals, height, offsetY, peaks }) => {
      // if drawBars was called within ws.empty we don't pass a start and
      // don't want anything to happen
      if (start === undefined) {
        return;
      }

      const barWith = this.params.barWidth ? this.params.barWidth : 3;
      const barGap = this.params.barGap ? this.params.barGap : 3;
      const pixelRatio = this.params.pixelRatio ? this.params.pixelRatio : 1;
      const bar = barWith * pixelRatio;

      // Skip every other value if there are negatives.
      const peakIndexScale = hasMinVals ? 2 : 1;
      const length = peaks.length / peakIndexScale;

      const gap =
        this.params.barGap === null
          ? Math.max(pixelRatio, ~~(bar / 2))
          : Math.max(pixelRatio, barGap * pixelRatio);
      const step = bar + gap;
      const gapBetween = 2;

      const scale = length / this.width;
      const first = start;
      const last = end;
      let i = first;

      function getPeak(peakIndex: number): number {
        const value = peaks[peakIndex];
        if (typeof value === "number") {
          return value;
        } else {
          return 0;
        }
      }

      for (i; i < last; i += step) {
        const peakIndex = Math.floor(i * scale * peakIndexScale);

        const peak = getPeak(peakIndex);

        const rectWidth = bar + this.halfPixel; // Width of the rectangle
        let rectHeight = Math.abs(
          Math.round((peak / absmax) * (height - gapBetween)),
        ); // Height of the rectangle

        /* in case of silences, allow the user to specify that we
         * always draw *something* (normally a 1px high bar) */
        if (
          rectHeight &&
          this.params.barMinHeight &&
          rectHeight < this.params.barMinHeight
        ) {
          rectHeight = this.params.barMinHeight;
        }

        const x = i + this.halfPixel; // X-position of the rectangle
        let y = 0; // Y-position of the rectangle

        const callType = store.getters[CallPlayerGetters.CALL].TYPE;
        if (offsetY) {
          y =
            callType === CallType.INBOUND
              ? height - rectHeight - gapBetween
              : height + gapBetween;
        } else {
          y =
            callType === CallType.INBOUND
              ? height + gapBetween
              : height - rectHeight - gapBetween;
        }

        const radius = this.barRadius; // Radius of the rectangle

        this.fillRect(x, y, rectWidth, rectHeight, radius, channelIndex);
      }
    },
    0,
    0,
  );
};

export default MultiCanvas;
