import { RouteRecordRaw } from "vue-router";
import CallReportPage from "@/views/pages/reports/call/CallReportPage.vue";
import ContactCenterPerAgentReportPage from "@/views/pages/reports/contactCenterPerAgent/ContactCenterPerAgentReportPage.vue";
import ContactCenterPerDayReportPage from "@/views/pages/reports/contactCenterPerDay/ContactCenterPerDayReportPage.vue";
import AgentProductivityPerAgentReportPage from "@/views/pages/reports/agentProductivityPerAgent/AgentProductivityPerAgentReportPage.vue";
import AgentProductivityPerDayReportPage from "@/views/pages/reports/agentProductivityPerDay/AgentProductivityPerDayReportPage.vue";
import RealtimeReportPage from "@/views/pages/reports/realtime/RealtimeReportPage.vue";
import QueueTimeReportPage from "@/views/pages/reports/queueTime/QueueTimeReportPage.vue";
import AgentExtensionReportPage from "@/views/pages/reports/realtime/AgentExtensionReportPage.vue";
import AgentDeviceReportPage from "@/views/pages/reports/agentDeviceStatusHistory/AgentDeviceReportPage.vue";

const reportRoutes: RouteRecordRaw = {
  name: "ReportGroup",
  path: "report",
  redirect: { name: "CallReport" },
  children: [
    {
      name: "CallReport",
      path: "call",
      component: CallReportPage,
    },
    {
      name: "ContactCenterPerAgentReport",
      path: "contact-center-per-agent",
      component: ContactCenterPerAgentReportPage,
    },
    {
      name: "ContactCenterPerDayReport",
      path: "contact-center-per-day",
      component: ContactCenterPerDayReportPage,
    },
    {
      name: "AgentProductivityPerAgentReport",
      path: "agent-productivity-per-agent",
      component: AgentProductivityPerAgentReportPage,
    },
    {
      name: "AgentProductivityPerDayReport",
      path: "agent-productivity-per-day",
      component: AgentProductivityPerDayReportPage,
    },
    {
      name: "QueueTimeReport",
      path: "queue-time",
      component: QueueTimeReportPage,
    },
    {
      name: "RealtimeReport",
      path: "realtime",
      component: RealtimeReportPage,
    },
    {
      name: "RealtimeAgentExtensionReport",
      path: "realtime/agent-extension",
      component: AgentExtensionReportPage,
    },
    {
      name: "AgentDeviceReportPage",
      path: "agent-device-status.vue",
      component: AgentDeviceReportPage,
    },
  ],
};

export default reportRoutes;
