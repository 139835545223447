/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IAudioPlayerState,
  AudioPlayerMutations,
  AudioPlayerActions,
  AudioPlayerGetters,
} from "@/definitions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WaveSurfer from "wavesurfer.js";
import MultiCanvas from "@/utils/multicanvas";

const state: IAudioPlayerState = {
  instance: null,
  playerReady: false,
  playerError: null,
  secondsDuration: 0,
  secondsCurrent: 0,
  loadingPercentage: 0,
};

const mutations: MutationTree<IAudioPlayerState> = {
  [AudioPlayerMutations.SET_INSTANCE](
    state: IAudioPlayerState,
    payload: WaveSurfer,
  ) {
    state.instance = payload;
  },
  [AudioPlayerMutations.UNSET_INSTANCE](state: IAudioPlayerState) {
    state.instance = null;
    state.playerReady = false;
    state.playerError = null;
    state.secondsDuration = 0;
    state.secondsCurrent = 0;
    state.loadingPercentage = 0;
  },
  [AudioPlayerMutations.PLAYER_READY](
    state: IAudioPlayerState,
    payload: boolean,
  ) {
    state.playerReady = payload;
  },
  [AudioPlayerMutations.SET_PLAYER_ERROR](
    state: IAudioPlayerState,
    payload: string,
  ) {
    state.playerError = payload;
  },
  [AudioPlayerMutations.SET_SECONDS_DURATION](
    state: IAudioPlayerState,
    payload: number,
  ) {
    state.secondsDuration = payload;
  },
  [AudioPlayerMutations.SET_SECONDS_CURRENT](
    state: IAudioPlayerState,
    payload: number,
  ) {
    state.secondsCurrent = payload;
  },
  [AudioPlayerMutations.SET_LOADING_PERCENTAGE](
    state: IAudioPlayerState,
    payload: number,
  ) {
    state.loadingPercentage = payload;
  },
};

const actions: ActionTree<IAudioPlayerState, any> = {
  [AudioPlayerActions.INIT_INSTANCE](
    { commit, getters, state },
    payload: { element: HTMLElement | string; fileUrl: string },
  ) {
    const instance = WaveSurfer.create({
      container: payload.element,
      waveColor: "#008BC5",
      progressColor: "#adb5bd",
      cursorColor: "#adb5bd",
      barWidth: 3,
      barGap: 3,
      barRadius: 1,
      barMinHeight: 3,
      height: 130,
      hideScrollbar: true,
      splitChannels: true,
      skipLength: 10,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderer: MultiCanvas,
    });

    instance.load(payload.fileUrl);

    instance.on("ready", function () {
      commit(AudioPlayerMutations.PLAYER_READY, true);
      commit(AudioPlayerMutations.SET_SECONDS_DURATION, instance.getDuration());
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance.on("audioprocess", (audioProcess) => {
      state.secondsCurrent = parseFloat(audioProcess.toFixed(2));
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance.on("loading", (percentage) => {
      commit(
        AudioPlayerMutations.SET_LOADING_PERCENTAGE,
        parseFloat(percentage.toFixed(2)),
      );
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance.on("error", (error) => {
      commit(AudioPlayerMutations.SET_LOADING_PERCENTAGE, 0);
      commit(AudioPlayerMutations.SET_PLAYER_ERROR, error);
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance.on("seek", (seek) => {
      const position = getters[AudioPlayerGetters.SECONDS_DURATION] * seek;

      commit(
        AudioPlayerMutations.SET_SECONDS_CURRENT,
        parseFloat(position.toFixed(2)),
      );
    });

    commit(AudioPlayerMutations.SET_INSTANCE, instance);
  },
  [AudioPlayerActions.PLAY]({ state }) {
    if (state.instance === null) {
      return;
    }

    state.instance.playPause();
  },
  [AudioPlayerActions.PLAY_SNIPPET]({ state }, payload: number[]) {
    if (state.instance === null) {
      return;
    }
    state.instance.play(payload[0], payload[1]);
  },
  [AudioPlayerActions.PLAYER_READY]({ commit }, payload: boolean) {
    commit(AudioPlayerMutations.PLAYER_READY, payload);
  },
};

const getters: GetterTree<IAudioPlayerState, any> = {
  [AudioPlayerGetters.INSTANCE]: (
    state: IAudioPlayerState,
  ): WaveSurfer | null => {
    return state.instance;
  },
  [AudioPlayerGetters.PLAYER_READY]: (state: IAudioPlayerState): boolean =>
    state.playerReady,
  [AudioPlayerGetters.PLAYER_ERROR]: (
    state: IAudioPlayerState,
  ): string | null => state.playerError,
  [AudioPlayerGetters.SECONDS_DURATION]: (state: IAudioPlayerState): number =>
    state.secondsDuration,
  [AudioPlayerGetters.SECONDS_CURRENT]: (state: IAudioPlayerState): number =>
    state.secondsCurrent,
  [AudioPlayerGetters.LOADING_PERCENTAGE]: (state: IAudioPlayerState): number =>
    state.loadingPercentage,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
