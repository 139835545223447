/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  ISortMenuItem,
  IMeetingState,
  MeetingMutations,
  MeetingActions,
  MeetingGetters,
  IMeetingFilter,
  IFilterItem,
} from "@/definitions";

const state: IMeetingState = {
  search: null,
  sort: [],
  page: 1,
  filter: {
    platform: null,
  },
};

const mutations: MutationTree<IMeetingState> = {
  [MeetingMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [MeetingMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [MeetingMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [MeetingMutations.SET_FILTER](state, filter: IMeetingFilter) {
    console.log("SET_FILTER chamado com:", filter);
    state.filter = { ...state.filter, ...filter };
    console.log("Estado atualizado para:", state.filter);
  },
  [MeetingMutations.CLEAR_FILTER](state, field?: string) {
    if (field === "platform") {
      state.filter.platform = null;
    } else {
      state.filter.platform = null;
    }
  },
};

const actions: ActionTree<IMeetingState, any> = {
  [MeetingActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(MeetingMutations.SET_SEARCH, search),
  [MeetingActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(MeetingMutations.SET_SORT, sort),
  [MeetingActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(MeetingMutations.SET_PAGE, page),
  [MeetingActions.SET_FILTER]: ({ commit }, filter: IMeetingFilter) => {
    console.log("Ação SET_FILTER chamada com:", filter);
    commit(MeetingMutations.SET_FILTER, filter);
  },
  [MeetingActions.CLEAR_FILTER]: ({ commit }, field?: string) =>
    commit(MeetingMutations.CLEAR_FILTER, field),
};

const getters: GetterTree<IMeetingState, any> = {
  [MeetingGetters.SEARCH]: (state): string | null => state.search,
  [MeetingGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [MeetingGetters.PAGE]: (state): number => state.page,
  [MeetingGetters.FILTER]: (state): IMeetingFilter => state.filter,
  [MeetingGetters.DEFAULT_FILTER]: (): IMeetingFilter => ({
    platform: null,
  }),
  [MeetingGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    if (state.filter.platform) {
      filters.push({
        field: "platform",
        label: state.filter.platform.label,
        value: state.filter.platform.value,
      });
    }

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
