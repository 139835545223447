import { IListable, IMultiSelectOption } from "@/definitions";

export interface IIntegrationLogFilter {
  status: IMultiSelectOption | null;
}

export interface IIntegrationLogState extends IListable {
  filter: IIntegrationLogFilter;
}

export enum IntegrationLogMutations {
  SET_SEARCH = "integrationLog_setSearch",
  SET_SORT = "integrationLog_setSort",
  SET_PAGE = "integrationLog_setPage",
  SET_FILTER = "integrationLog_filter_setFilter",
  CLEAR_FILTER = "integrationLog_filter_clearFilter",
}

export enum IntegrationLogActions {
  SET_SEARCH = "integrationLog_setSearch",
  SET_SORT = "integrationLog_setSort",
  SET_PAGE = "integrationLog_setPage",
  SET_FILTER = "integrationLog_filter_SetFilter",
  CLEAR_FILTER = "integrationLog_filter_ClearFilter",
}

export enum IntegrationLogGetters {
  SEARCH = "integrationLog_search",
  SORT = "integrationLog_sort",
  PAGE = "integrationLog_page",
  DEFAULT_FILTER = "integrationLog_default_filter",
  FILTER = "integrationLog_filter",
  ACTIVE_FILTERS = "integrationLog_active_filters",
}
