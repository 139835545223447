import {
  CallServiceTimeRange,
  CallStatus,
  CallType,
  INumber,
  IUser,
  IMultiSelectOption,
  IUserLabel,
} from "@/definitions";

export interface IContactCenterReportFilter {
  type: IMultiSelectOption<CallType> | null;
  status?: IMultiSelectOption<CallStatus> | null;
  user?: IUser | null;
  number?: INumber | null;
  serviceTimeRange?: IMultiSelectOption<CallServiceTimeRange> | null;
  userLabel: IUserLabel | null;
}
export interface IContactCenterReportState {
  filter: IContactCenterReportFilter;
}

export enum ContactCenterReportMutations {
  SET_FILTER = "contactCenterReport_setFilter",
  CLEAR_FILTER = "contactCenterReport_clearFilter",
}

export enum ContactCenterReportActions {
  SET_FILTER = "contactCenterReport_setFilter",
  CLEAR_FILTER = "contactCenterReport_clearFilter",
}

export enum ContactCenterReportGetters {
  DEFAULT_FILTER = "contactCenterReport_defaultFilter",
  FILTER = "contactCenterReport_filter",
  ACTIVE_FILTERS = "contactCenterReport_activeFilters",
}
