import { IListable, IMultiSelectOption } from "@/definitions";

export interface INumberFilter {
  state: IMultiSelectOption | null;
  city: IMultiSelectOption | null;
}

export interface INumberState extends IListable {
  filter: INumberFilter;
}

export enum NumberMutations {
  SET_SEARCH = "number_setSearch",
  SET_SORT = "number_setSort",
  SET_PAGE = "number_setPage",
  SET_FILTER = "number_filter_setFilter",
  CLEAR_FILTER = "number_filter_clearFilter",
}

export enum NumberActions {
  SET_SEARCH = "number_setSearch",
  SET_SORT = "number_setSort",
  SET_PAGE = "number_setPage",
  SET_FILTER = "number_filter_SetFilter",
  CLEAR_FILTER = "number_filter_ClearFilter",
}

export enum NumberGetters {
  SEARCH = "number_search",
  SORT = "number_sort",
  PAGE = "number_page",
  DEFAULT_FILTER = "number_default_filter",
  FILTER = "numberFilter",
  ACTIVE_FILTERS = "numberActiveFilters",
}
