/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import moment from "moment";
import {
  IFilterState,
  FilterMutations,
  FilterActions,
  FilterGetters,
  ILevel,
} from "@/definitions";

const startDate = moment.utc().startOf("day").toDate();
const endDate = moment.utc().startOf("day").subtract(6, "d").toDate();

const state: IFilterState = {
  level: null,
  dateRange: [endDate, startDate],
};

const mutations: MutationTree<IFilterState> = {
  [FilterMutations.SET_LEVEL](state, level: ILevel) {
    state.level = level;
  },
  [FilterMutations.SET_DATE_RANGE](state, dateRange: Array<Date>) {
    state.dateRange = dateRange;
  },
};

const actions: ActionTree<IFilterState, any> = {
  [FilterActions.SET_LEVEL]({ commit }, level: ILevel) {
    commit(FilterMutations.SET_LEVEL, level);
    localStorage.setItem("level", btoa(JSON.stringify(level)));
  },
  [FilterActions.SET_DATE_RANGE]({ commit }, dateRange: Array<Date>) {
    commit(FilterMutations.SET_DATE_RANGE, dateRange);
  },
};

const getters: GetterTree<IFilterState, any> = {
  [FilterGetters.LEVEL](state): ILevel | null {
    return state.level;
  },
  [FilterGetters.DATE_RANGE](state): Array<Date> | null {
    return state.dateRange;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
