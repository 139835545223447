import { IUser } from "@/definitions";

export interface IAuthState {
  user?: IUser;
}

export enum AuthMutations {
  SET_USER = "auth_setUser",
}

export enum AuthActions {
  LOGIN = "auth_login",
  LOGOUT = "auth_logout",
}

export enum AuthGetters {
  USER = "auth_user",
  USER_LEVELS = "auth_user_levels",
  USER_ROLES = "auth_user_roles",
  USER_PERMISSIONS = "auth_user_permissions",
  CHAT_BOT_INTEGRATION = "auth_get_chat_bot_integration",
}
