/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IWordState,
  IWordFilter,
  WordMutations,
  WordActions,
  WordGetters,
  ISortMenuItem,
  IFilterItem,
} from "@/definitions";
import { useI18n } from "vue-i18n";

const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      callParticipant: null,
      isActive: null,
    },
  } as IWordState;
};

const state: IWordState = getDefaultState();

const mutations: MutationTree<IWordState> = {
  [WordMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [WordMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [WordMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [WordMutations.SET_FILTER](state, payload: IWordFilter) {
    state.filter.callParticipant = payload.callParticipant;
    state.filter.isActive = payload.isActive;
  },
  [WordMutations.CLEAR_FILTER](state, field?: string) {
    switch (field) {
      case "callParticipant":
        state.filter.callParticipant = getDefaultState().filter.callParticipant;
        break;
      case "isActive":
        state.filter.isActive = getDefaultState().filter.isActive;
        break;
      default:
        state.filter.callParticipant = getDefaultState().filter.callParticipant;
        state.filter.isActive = getDefaultState().filter.isActive;
    }
  },
};

const actions: ActionTree<IWordState, any> = {
  [WordActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(WordMutations.SET_SEARCH, search),
  [WordActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(WordMutations.SET_SORT, sort),
  [WordActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(WordMutations.SET_PAGE, page),
  [WordActions.SET_FILTER]({ commit }, payload: IWordFilter) {
    commit(WordMutations.SET_FILTER, payload);
  },
  [WordActions.CLEAR_FILTER]({ commit }, field?: string) {
    commit(WordMutations.CLEAR_FILTER, field);
  },
};

const getters: GetterTree<IWordState, any> = {
  [WordGetters.SEARCH]: (state): string | null => state.search,
  [WordGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [WordGetters.PAGE]: (state): number => state.page,
  [WordGetters.DEFAULT_FILTER]: (): IWordFilter => getDefaultState().filter,
  [WordGetters.FILTER]: (state): IWordFilter => state.filter,
  [WordGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    if (state.filter.callParticipant !== null) {
      filters.push({
        field: "callParticipant",
        value: state.filter.callParticipant.value,
        label: state.filter.callParticipant.label,
      });
    }

    const { t } = useI18n();
    if (state.filter.isActive !== null) {
      filters.push({
        field: "isActive.label",
        value: state.filter.isActive,
        label: t(
          `word.filters.activeTag.isActive.${state.filter.isActive.toString()}`,
        ),
      });
    }

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
