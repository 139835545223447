import { RouteRecordRaw } from "vue-router";
import {
  idIsPositiveInteger,
  typeIsValidIntegrationType,
} from "@/router/routeGuards";
import { FormAction } from "@/definitions";
import { IntegrationPage, IntegrationLogPage } from "@/views/pages/integration";
import { IntegrationDynamicForm } from "@/views/pages/integration/components";

const integrationRouter: RouteRecordRaw = {
  name: "Integration",
  path: "integration",
  children: [
    {
      name: "ListIntegration",
      path: "",
      component: IntegrationPage,
    },
    {
      name: "ViewIntegrationLogs",
      path: ":type/:id/logs",
      component: IntegrationLogPage,
      beforeEnter: [idIsPositiveInteger, typeIsValidIntegrationType],
      props: (route) => ({
        type: route.params.type,
      }),
    },
    {
      name: "CreateIntegration",
      path: ":type/create",
      component: IntegrationDynamicForm,
      beforeEnter: [typeIsValidIntegrationType],
      props: { action: FormAction.CREATE },
    },
    {
      name: "EditIntegrationById",
      path: ":type/:id/edit",
      component: IntegrationDynamicForm,
      beforeEnter: [idIsPositiveInteger, typeIsValidIntegrationType],
      props: { action: FormAction.EDIT },
    },
  ],
};

export default integrationRouter;
