import { IListable, IMultiSelectOption } from "@/definitions";

export interface IMeetingFilter {
  platform: IMultiSelectOption | null;
}
export interface IMeetingState extends IListable {
  filter: IMeetingFilter;
}

export enum MeetingMutations {
  SET_SEARCH = "meeting_setSearch",
  SET_SORT = "meeting_setSort",
  SET_PAGE = "meeting_setPage",
  SET_FILTER = "meeting_setFilter",
  CLEAR_FILTER = "meeting_clearFilter",
}

export enum MeetingActions {
  SET_SEARCH = "meeting_setSearch",
  SET_SORT = "meeting_setSort",
  SET_PAGE = "meeting_setPage",
  SET_FILTER = "meeting_setFilter",
  CLEAR_FILTER = "meeting_clearFilter",
}

export enum MeetingGetters {
  SEARCH = "meeting_search",
  SORT = "meeting_sort",
  PAGE = "meeting_page",
  FILTER = "meeting_filter",
  DEFAULT_FILTER = "meeting_defaultFilter",
  ACTIVE_FILTERS = "meeting_activeFilters",
}
