export interface ISortMenuItem {
  label: string;
  key: string;
  direction?: ISortMenuItemDirection;
}

export enum ISortMenuItemDirection {
  ASC = "+",
  DESC = "-",
}
