/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IQueueState,
  QueueMutations,
  QueueActions,
  QueueGetters,
  IQueueFilter,
  ISortMenuItem,
} from "@/definitions";
const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      isActive: null,
    },
  } as IQueueState;
};

const state: IQueueState = getDefaultState();

const mutations: MutationTree<IQueueState> = {
  [QueueMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [QueueMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [QueueMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [QueueMutations.SET_FILTER](state, filter: IQueueFilter) {
    state.filter.isActive = filter.isActive;
  },
  [QueueMutations.CLEAR_FILTER](state, field?: string) {
    // This makes no sense but ok...
    switch (field) {
      case "isActive":
        state.filter.isActive = getDefaultState().filter.isActive;
        break;
      default:
        state.filter.isActive = getDefaultState().filter.isActive;
    }
  },
};

const actions: ActionTree<IQueueState, any> = {
  [QueueActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(QueueMutations.SET_SEARCH, search),
  [QueueActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(QueueMutations.SET_SORT, sort),
  [QueueActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(QueueMutations.SET_PAGE, page),
  [QueueActions.SET_FILTER]: ({ commit }, filter: IQueueFilter) =>
    commit(QueueMutations.SET_FILTER, filter),
  [QueueActions.CLEAR_FILTER]: ({ commit }, filter: IQueueFilter) =>
    commit(QueueMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<IQueueState, any> = {
  [QueueGetters.SEARCH]: (state): string | null => state.search,
  [QueueGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [QueueGetters.PAGE]: (state): number => state.page,
  [QueueGetters.FILTER]: (state): IQueueFilter => state.filter,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
