import { IUserLabel, IUser } from "@/definitions";

export interface IAgentProductivityPerDayReportFilter {
  user?: IUser | null;
  userLabel?: IUserLabel | null;
}
export interface IAgentProductivityPerDayReportState {
  filter: IAgentProductivityPerDayReportFilter;
}

export enum AgentProductivityPerDayReportMutations {
  SET_FILTER = "agentProductivityPerDayReport_setFilter",
  CLEAR_FILTER = "agentProductivityPerDayReport_clearFilter",
}

export enum AgentProductivityPerDayReportActions {
  SET_FILTER = "agentProductivityPerDayReport_setFilter",
  CLEAR_FILTER = "agentProductivityPerDayReport_clearFilter",
}

export enum AgentProductivityPerDayReportGetters {
  DEFAULT_FILTER = "agentProductivityPerDayReport_defaultFilter",
  FILTER = "agentProductivityPerDayReport_filter",
  ACTIVE_FILTERS = "agentProductivityPerDayReport_activeFilters",
}
