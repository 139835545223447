/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IPermissionState,
  PermissionMutations,
  PermissionActions,
  PermissionGetters,
  ISortMenuItem,
} from "@/definitions";

const state: IPermissionState = {
  search: null,
  sort: [],
  page: 1,
};

const mutations: MutationTree<IPermissionState> = {
  [PermissionMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [PermissionMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [PermissionMutations.SET_PAGE]: (state, page: number) => (state.page = page),
};

const actions: ActionTree<IPermissionState, any> = {
  [PermissionActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(PermissionMutations.SET_SEARCH, search),
  [PermissionActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(PermissionMutations.SET_SORT, sort),
  [PermissionActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(PermissionMutations.SET_PAGE, page),
};

const getters: GetterTree<IPermissionState, any> = {
  [PermissionGetters.SEARCH]: (state): string | null => state.search,
  [PermissionGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [PermissionGetters.PAGE]: (state): number => state.page,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
