import { ICall, IMultiSelectOption } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class CallService<T> extends BaseService<ICall> {
  getTimeline<ResponseT = T[]>(
    id: number | string,
    config?: AxiosRequestConfig,
  ) {
    return this.http.get<ResponseT>(`${this.route}/${id}/timeline`, config);
  }
  getWords<ResponseT = T[]>(id: number | string, config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/${id}/words`, config);
  }
  getMedias<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/options/medias/`, config);
  }
  getClientNumbers<ResponseT = IMultiSelectOption[]>(
    config?: AxiosRequestConfig,
  ) {
    return this.http.get<ResponseT>(`${this.route}/number/client`, config);
  }
  getAgentNumbers<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/number/agent`, config);
  }
  getCsvReport<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/report/csv`, config);
  }
}

export default new CallService("calls");
