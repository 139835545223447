import { INavigationItem } from "@/definitions";

export interface INavigationState {
  sideNavigationOpen: boolean;
  sideNavigationItems: INavigationItem[];
  configSideNavigationItems: INavigationItem[];
}

export enum NavigationMutations {
  SET_SIDE_NAVIGATION_OPEN = "setSideNavigationOpen",
}

export enum NavigationActions {
  TOGGLE_SIDE_NAVIGATION = "toggleSideNavigation",
}

export enum NavigationGetters {
  SIDE_NAVIGATION_OPEN = "sideNavigationOpen",
  SIDE_NAVIGATION_ITEMS = "sideNavigationItems",
  CONFIG_SIDE_NAVIGATION_ITEMS = "configSideNavigationItems",
}
