import http from "@/utils/httpCommon";
import { AxiosInstance } from "axios";

class VaultService {
  route: string;
  http: AxiosInstance;

  constructor(route: string) {
    this.route = route;
    this.http = http;
  }

  getAudioAsset<ResponseT = BinaryData>(file: string) {
    return this.http.get<ResponseT>(
      `${this.route}/stream/asset/audio/${file}`,
      {
        responseType: "blob",
      },
    );
    // .then((response: AxiosResponse) => response.data);
  }

  getCallAudio<ResponseT = BinaryData>(callId: string | number) {
    return this.http.get<ResponseT>(
      `${this.route}/stream/call/${callId}/audio`,
      {
        responseType: "blob",
      },
    );
    // .then((response: AxiosResponse) => response.data);
  }
}

export default new VaultService("vault");
