import {
  IConnectFlow,
  IConnectFlowFlow,
  IDefaultServiceResult,
  IFlowConnection,
  IFlowNode,
} from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class ConnectFlowService<T> extends BaseService<IConnectFlow> {
  activate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
  };
  inactivate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(
      `${this.route}/${id}/inactivate`,
    );
  };
  getFlow = (id: number, historyId?: number) => {
    return this.http.get<IConnectFlowFlow>(`${this.route}/${id}/flow`, {
      params: { historyId: historyId },
    });
  };
  saveFlow<ResponseT = T[]>(id: string, data: (IFlowNode | IFlowConnection)[]) {
    return this.http.post<ResponseT>(`${this.route}/${id}/flow`, data);
  }
  publishFlow<ResponseT = T[]>(
    id: string,
    data: (IFlowNode | IFlowConnection)[],
  ) {
    return this.http.put<ResponseT>(`${this.route}/${id}/flow-publish`, data);
  }
  getFlowHistory<ResponseT = T[]>(
    id: number | string,
    config?: AxiosRequestConfig,
  ) {
    return this.http.get<ResponseT>(`${this.route}/${id}/history`, config);
  }
}

export default new ConnectFlowService("connect-flows");
