/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18n } from "@/utils/locale";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  ISortMenuItem,
  ICallState,
  CallMutations,
  CallActions,
  CallGetters,
  ICallFilter,
  IFilterItem,
} from "@/definitions";

const state: ICallState = {
  search: null,
  sort: [],
  page: 1,
  filter: {
    type: null,
    status: null,
    userLabel: null,
    media: null,
    word: null,
    user: null,
    numberClient: null,
  },
};

const mutations: MutationTree<ICallState> = {
  [CallMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [CallMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [CallMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [CallMutations.SET_FILTER](state, filter: ICallFilter) {
    state.filter.type = filter.type;
    state.filter.status = filter.status;
    state.filter.userLabel = filter.userLabel;
    state.filter.media = filter.media;
    state.filter.word = filter.word;
    state.filter.numberClient = filter.numberClient;
    state.filter.user = filter.user;
  },
  [CallMutations.CLEAR_FILTER](state, field?: string) {
    switch (field) {
      case "type":
        state.filter.type = null;
        break;
      case "status":
        state.filter.status = null;
        break;
      case "userLabel":
        state.filter.userLabel = null;
        break;
      case "media":
        state.filter.media = null;
        break;
      case "numberClient":
        state.filter.numberClient = null;
        break;
      case "word":
        state.filter.word = null;
        break;
      case "user":
        state.filter.user = null;
        break;
      default:
        state.filter.type = null;
        state.filter.status = null;
        state.filter.userLabel = null;
        state.filter.media = null;
        state.filter.numberClient = null;
        state.filter.word = null;
        state.filter.user = null;
    }
  },
};

const actions: ActionTree<ICallState, any> = {
  [CallActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(CallMutations.SET_SEARCH, search),
  [CallActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(CallMutations.SET_SORT, sort),
  [CallActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(CallMutations.SET_PAGE, page),
  [CallActions.SET_FILTER]: ({ commit }, filter: ICallFilter) =>
    commit(CallMutations.SET_FILTER, filter),
  [CallActions.CLEAR_FILTER]: ({ commit }, filter: ICallFilter) =>
    commit(CallMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<ICallState, any> = {
  [CallGetters.SEARCH]: (state): string | null => state.search,
  [CallGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [CallGetters.PAGE]: (state): number => state.page,
  [CallGetters.FILTER]: (state): ICallFilter => state.filter,
  [CallGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];
    const { t } = i18n.global;
    if (state.filter.type) {
      filters.push({
        field: "type",
        label: state.filter.type.label,
        value: state.filter.type.label,
      });
    }

    if (state.filter.status) {
      filters.push({
        field: "status",
        label: state.filter.status?.label.toString(),
        value: state.filter.status.value,
      });
    }

    if (state.filter.userLabel) {
      filters.push({
        field: "userLabel",
        label: state.filter.userLabel?.label,
        value: state.filter.userLabel?.id,
      });
    }

    if (state.filter.media) {
      filters.push({
        field: "media",
        label: state.filter.media?.label.toString(),
        value: state.filter.media.value,
      });
    }

    if (state.filter.numberClient) {
      filters.push({
        field: "numberClient",
        label: state.filter.numberClient?.label.toString(),
        value: state.filter.numberClient.value,
      });
    }

    if (state.filter.word) {
      filters.push({
        field: "word",
        label:
          t(`word.participant.${state.filter.word?.callParticipant}`) +
          " - " +
          state.filter.word?.word.toString(),
        value: state.filter.word.word,
      });
    }

    if (state.filter.user) {
      filters.push({
        field: "user",
        label: state.filter.user?.name,
        value: state.filter.user?.id,
      });
    }

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
