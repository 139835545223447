import { IUser, ILevel } from "@/definitions";

export interface IIntegration {
  id: number;
  levelId: number;
  level: ILevel;
  type: IntegrationType;
  isActive: boolean;
  createdBy: IUser;
  createdAt: string;
  updatedBy?: number;
  updatedAt?: string;
  createdByUser: IUser;
  updatedByUser?: IUser;
}

export interface IPipedriveIntegration extends IIntegration {
  token: string;
  checkActivityAsDone: boolean;
  associateWithDeal: boolean;
}

export interface IOpenAiIntegration extends IIntegration {
  token: string;
  model: string;
}

export enum IntegrationType {
  Pipedrive = "pipedrive",
  OpenAi = "openai",
}
