import { IAgentProductivityReportServiceGetInfoResult } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class AgentProductivityPerAgentReportService extends BaseService<IAgentProductivityReportServiceGetInfoResult> {
  getInfo(config?: AxiosRequestConfig) {
    return this.http.get<IAgentProductivityReportServiceGetInfoResult>(
      `${this.route}`,
      config,
    );
  }
}

export default new AgentProductivityPerAgentReportService(
  "reports/agent-productivity-per-agent",
);
