import {
  INumberFormState,
  INumber,
  IDefaultServiceResult,
  IFormNumberAgent,
} from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class NumberService<T> extends BaseService<INumber | INumberFormState> {
  activate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
  };
  inactivate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(
      `${this.route}/${id}/inactivate`,
    );
  };
  getStates<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/options/states/`, config);
  }
  getCities<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/options/cities/`, config);
  }
  checkExtensionNumber<ResponseT = T[]>(
    siblings: IFormNumberAgent,
    config?: AxiosRequestConfig,
  ) {
    return this.http.post<ResponseT>(
      `${this.route}/check-extension-number`,
      siblings,
      config,
    );
  }
}

export default new NumberService("numbers");
