/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  CallPlayerGetters,
  CallPlayerMutations,
  ICall,
  ICallPlayerState,
  ICallTimelineItem,
  IWordsByPersona,
} from "@/definitions";

const state: ICallPlayerState = {
  call: null,
  timeline: [],
  words: {
    client: [],
    agent: [],
  },
};

const mutations: MutationTree<ICallPlayerState> = {
  [CallPlayerMutations.SET_CALL]: (state, call: ICall) => (state.call = call),
  [CallPlayerMutations.SET_TIMELINE]: (state, timeline: ICallTimelineItem[]) =>
    (state.timeline = timeline),
  [CallPlayerMutations.SET_WORDS]: (state, words: IWordsByPersona) =>
    (state.words = words),
};

const actions: ActionTree<ICallPlayerState, any> = {};

const getters: GetterTree<ICallPlayerState, any> = {
  [CallPlayerGetters.CALL]: (state): ICall | null => state.call,
  [CallPlayerGetters.TIMELINE]: (state): ICallTimelineItem[] => state.timeline,
  [CallPlayerGetters.WORDS]: (state): IWordsByPersona => state.words,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
