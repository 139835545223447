import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";
import {
  IAgentDeviceStatusHistoryReportServiceGetInfoParams,
  IAgentDeviceStatusHistoryReportServiceGetInfoResult,
} from "@/definitions/service-typings/AgentDeviceStatusHistoryReportService";

class AgentDeviceStatusHistoryReportService extends BaseService<IAgentDeviceStatusHistoryReportServiceGetInfoParams> {
  getInfo(config?: AxiosRequestConfig) {
    return this.http.get<IAgentDeviceStatusHistoryReportServiceGetInfoResult>(
      `${this.route}`,
      config,
    );
  }
}

export default new AgentDeviceStatusHistoryReportService(
  "reports/agent-devices-status-history",
);
