/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IConnectFlowAudioState,
  AudioMutations,
  AudioActions,
  AudioGetters,
  IConnectFlowAudioFilter,
  ISortMenuItem,
} from "@/definitions";
const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      isActive: null,
    },
  } as IConnectFlowAudioState;
};

const state: IConnectFlowAudioState = getDefaultState();

const mutations: MutationTree<IConnectFlowAudioState> = {
  [AudioMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [AudioMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [AudioMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [AudioMutations.SET_FILTER](state, filter: IConnectFlowAudioFilter) {
    state.filter.isActive = filter.isActive;
  },
  [AudioMutations.CLEAR_FILTER](state, field?: string) {
    // This makes no sense but ok...
    switch (field) {
      case "isActive":
        state.filter.isActive = getDefaultState().filter.isActive;
        break;
      default:
        state.filter.isActive = getDefaultState().filter.isActive;
    }
  },
};

const actions: ActionTree<IConnectFlowAudioState, any> = {
  [AudioActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(AudioMutations.SET_SEARCH, search),
  [AudioActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(AudioMutations.SET_SORT, sort),
  [AudioActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(AudioMutations.SET_PAGE, page),
  [AudioActions.SET_FILTER]: ({ commit }, filter: IConnectFlowAudioFilter) =>
    commit(AudioMutations.SET_FILTER, filter),
  [AudioActions.CLEAR_FILTER]: ({ commit }, filter: IConnectFlowAudioFilter) =>
    commit(AudioMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<IConnectFlowAudioState, any> = {
  [AudioGetters.SEARCH]: (state): string | null => state.search,
  [AudioGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [AudioGetters.PAGE]: (state): number => state.page,
  [AudioGetters.FILTER]: (state): IConnectFlowAudioFilter => state.filter,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
