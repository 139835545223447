export interface IFilterItem {
  field: string;
  label: string;
  value: object | string | number | boolean | null;
}

export const FILTER_OPTION_ALL = "all";

export enum IFilterOption {
  ALL = "all",
  ALL_BY_ID = 0,
}
