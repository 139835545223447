import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import SpamNumberPage from "@/views/pages/spamNumber/SpamNumberPage.vue";
import SpamNumberDetailPage from "@/views/pages/spamNumber/SpamNumberDetailPage.vue";
import SpamNumberFormPage from "@/views/pages/spamNumber/SpamNumberFormPage.vue";
import { FormAction } from "@/definitions";

const numberRouter: RouteRecordRaw = {
  name: "SpamNumber",
  path: "spam-number",
  children: [
    {
      name: "ListSpamNumber",
      path: "",
      component: SpamNumberPage,
    },
    {
      name: "ViewSpamNumberById",
      path: ":id",
      component: SpamNumberDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditSpamNumberById",
      path: ":id/edit",
      component: SpamNumberFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateSpamNumber",
      path: "create",
      component: SpamNumberFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};

export default numberRouter;
