/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IUserState,
  UserMutations,
  UserActions,
  UserGetters,
  IUserFilter,
  ISortMenuItem,
  IFilterItem,
} from "@/definitions";

const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      roles: [],
      isActive: null,
    },
  } as IUserState;
};

const state: IUserState = getDefaultState();

const mutations: MutationTree<IUserState> = {
  [UserMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [UserMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [UserMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [UserMutations.SET_FILTER](state, filter: IUserFilter) {
    state.filter.roles = filter.roles;
    state.filter.isActive = filter.isActive;
  },
  [UserMutations.CLEAR_FILTER](state, field?: string) {
    // This makes no sense but ok...
    switch (field) {
      case "role":
        state.filter.roles = getDefaultState().filter.roles;
        break;
      case "isActive":
        state.filter.isActive = getDefaultState().filter.isActive;
        break;
      default:
        state.filter.roles = getDefaultState().filter.roles;
        state.filter.isActive = getDefaultState().filter.isActive;
    }
  },
};

const actions: ActionTree<IUserState, any> = {
  [UserActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(UserMutations.SET_SEARCH, search),
  [UserActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(UserMutations.SET_SORT, sort),
  [UserActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(UserMutations.SET_PAGE, page),
  [UserActions.SET_FILTER]: ({ commit }, filter: IUserFilter) =>
    commit(UserMutations.SET_FILTER, filter),
  [UserActions.CLEAR_FILTER]: ({ commit }, filter: IUserFilter) =>
    commit(UserMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<IUserState, any> = {
  [UserGetters.SEARCH]: (state): string | null => state.search,
  [UserGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [UserGetters.PAGE]: (state): number => state.page,
  [UserGetters.FILTER]: (state): IUserFilter => state.filter,
  [UserGetters.DEFAULT_FILTER]: (): IUserFilter => getDefaultState().filter,
  [UserGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    state.filter.roles.forEach((role) => {
      filters.push({
        field: "role",
        value: role.id,
        label: role.name,
      });
    });

    if (state.filter.isActive !== null) {
      filters.push({
        field: "isActive",
        value: state.filter.isActive.value,
        label: state.filter.isActive.label,
      });
    }

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
