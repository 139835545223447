import {
  CallServiceTimeRange,
  CallStatus,
  CallType,
  IUserLabel,
  INumber,
  IUser,
  IMultiSelectOption,
} from "@/definitions";

export interface IContactCenterPerDayReportFilter {
  type: IMultiSelectOption<CallType> | null;
  status?: IMultiSelectOption<CallStatus> | null;
  user?: IUser | null;
  number?: INumber | null;
  serviceTimeRange?: IMultiSelectOption<CallServiceTimeRange> | null;
  userLabel: IUserLabel | null;
}
export interface IContactCenterPerDayReportState {
  filter: IContactCenterPerDayReportFilter;
}

export enum ContactCenterPerDayReportMutations {
  SET_FILTER = "contactCenterPerDayReport_setFilter",
  CLEAR_FILTER = "contactCenterPerDayReport_clearFilter",
}

export enum ContactCenterPerDayReportActions {
  SET_FILTER = "contactCenterPerDayReport_setFilter",
  CLEAR_FILTER = "contactCenterPerDayReport_clearFilter",
}

export enum ContactCenterPerDayReportGetters {
  DEFAULT_FILTER = "contactCenterPerDayReport_defaultFilter",
  FILTER = "contactCenterPerDayReport_filter",
  ACTIVE_FILTERS = "contactCenterPerDayReport_activeFilters",
}
