import {
  IFlowNodeData,
  NodeType,
} from "@/definitions/ui-typings/IConnectFlowModeling";

interface IAutoChildren {
  data: IFlowNodeData;
  sourceHandle?: string;
  position: {
    x: number;
    y?: number;
  };
}

export interface INodeRules {
  maxChildren: number;
  typesChildren: string[];
  autoChildren: IAutoChildren[];
  position: { x: number; y?: number };
}

export const NodeStart: INodeRules = {
  maxChildren: 1,
  typesChildren: [
    NodeType.TIME_VALIDATION,
    NodeType.PLAY_AUDIO,
    NodeType.CLIENT_INFO,
    NodeType.TRANSFER_EXTENSION,
    NodeType.TRANSFER_QUEUE,
    NodeType.TRANSFER_EXTERNAL,
    NodeType.HANG_UP,
  ],
  autoChildren: [],
  position: {
    x: 0,
  },
};

export const NodeTimeValidation: INodeRules = {
  maxChildren: 2,
  typesChildren: [
    NodeType.TIME_VALIDATION,
    NodeType.PLAY_AUDIO,
    NodeType.CLIENT_INFO,
    NodeType.TRANSFER_EXTENSION,
    NodeType.TRANSFER_EXTERNAL,
    NodeType.HANG_UP,
  ],
  position: {
    x: -75,
  },
  autoChildren: [
    {
      data: {
        title: "connectFlow.node.timeValidation.inTime",
        component: NodeType.OPEN_EDGE,
        flowType: "inTime",
        related: null,
        hasErrors: false,
      },
      position: {
        x: -50,
        y: 200,
      },
    },
    {
      data: {
        title: "connectFlow.node.timeValidation.outOfTime",
        component: NodeType.OPEN_EDGE,
        flowType: "outOfTime",
        related: null,
        hasErrors: false,
      },
      position: {
        x: 325,
        y: 200,
      },
    },
  ],
};

export const PlayAudioValidation: INodeRules = {
  maxChildren: 1,
  typesChildren: [
    NodeType.TIME_VALIDATION,
    NodeType.PLAY_AUDIO,
    NodeType.CLIENT_INFO,
    NodeType.TRANSFER_EXTENSION,
    NodeType.TRANSFER_QUEUE,
    NodeType.TRANSFER_EXTERNAL,
    NodeType.HANG_UP,
  ],
  autoChildren: [
    {
      data: {
        title: "connectFlow.node.playAudio.end",
        component: NodeType.OPEN_EDGE,
        related: null,
        hasErrors: false,
        flowType: "end",
      },
      position: {
        x: 135,
        y: 200,
      },
    },
  ],
  position: { x: -75 },
};

export const OpenEdgeValidation: INodeRules = {
  maxChildren: -1,
  typesChildren: [
    NodeType.TIME_VALIDATION,
    NodeType.PLAY_AUDIO,
    NodeType.CLIENT_INFO,
    NodeType.TRANSFER_EXTENSION,
    NodeType.TRANSFER_QUEUE,
    NodeType.TRANSFER_EXTERNAL,
    NodeType.HANG_UP,
    NodeType.GO_TO,
  ],
  autoChildren: [],
  position: { x: 0 },
};

export const ClientInfoValidation: INodeRules = {
  maxChildren: 12,
  typesChildren: [
    NodeType.TIME_VALIDATION,
    NodeType.PLAY_AUDIO,
    NodeType.CLIENT_INFO,
    NodeType.TRANSFER_EXTENSION,
    NodeType.TRANSFER_QUEUE,
    NodeType.TRANSFER_EXTERNAL,
    NodeType.HANG_UP,
    NodeType.GO_TO,
  ],
  autoChildren: [
    {
      data: {
        title: "connectFlow.node.clientInfo.timeout",
        component: NodeType.OPEN_EDGE,
        related: null,
        hasErrors: false,
        flowType: "timeout",
      },
      sourceHandle: "timeout",
      position: {
        x: -150,
        y: -30,
      },
    },
    {
      data: {
        title: "connectFlow.node.clientInfo.invalidOption",
        component: NodeType.OPEN_EDGE,
        related: null,
        hasErrors: false,
        flowType: "invalid",
      },
      sourceHandle: "invalid",
      position: {
        x: -150,
        y: 110,
      },
    },
  ],
  position: { x: 0, y: 250 },
};

export const TransferToValidation: INodeRules = {
  maxChildren: 2,
  typesChildren: [NodeType.TRANSFER_SURVEY],
  autoChildren: [
    {
      data: {
        title: "connectFlow.node.transferToExtension.noAnswer",
        component: NodeType.OPEN_EDGE,
        related: null,
        hasErrors: false,
        flowType: "noAnswer",
      },
      position: {
        x: -50,
        y: 200,
      },
    },
  ],
  position: { x: 0 },
};

export const BasicValidation: INodeRules = {
  maxChildren: 0,
  typesChildren: [],
  autoChildren: [],
  position: { x: 0 },
};

export default {
  [NodeType.START]: NodeStart,
  [NodeType.TIME_VALIDATION]: NodeTimeValidation,
  [NodeType.PLAY_AUDIO]: PlayAudioValidation,
  [NodeType.OPEN_EDGE]: OpenEdgeValidation,
  [NodeType.CLIENT_INFO]: ClientInfoValidation,
  [NodeType.TRANSFER_EXTENSION]: TransferToValidation,
  [NodeType.TRANSFER_EXTERNAL]: BasicValidation,
  [NodeType.TRANSFER_QUEUE]: TransferToValidation,
  [NodeType.TRANSFER_SURVEY]: BasicValidation,
  [NodeType.HANG_UP]: BasicValidation,
  [NodeType.GO_TO]: BasicValidation,
};
