import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import { FormAction } from "@/definitions";
import AudioPage from "@/views/pages/connect-flow-audio/AudioPage.vue";
import AudioDetailPage from "@/views/pages/connect-flow-audio/AudioDetailPage.vue";
import AudioFormPage from "@/views/pages/connect-flow-audio/AudioFormPage.vue";

const connectFlowAudio: RouteRecordRaw = {
  name: "connectFlowAudio",
  path: "connect-flow/audio",
  children: [
    {
      name: "ListConnectFlowAudio",
      path: "",
      component: AudioPage,
    },
    {
      name: "ViewConnectFlowAudioById",
      path: ":id",
      component: AudioDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditConnectFlowAudioById",
      path: ":id/edit",
      component: AudioFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateConnectFlowAudio",
      path: "create",
      component: AudioFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};
export default connectFlowAudio;
