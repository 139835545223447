import { IListable, IMultiSelectOption } from "@/definitions";

export interface IConnectFlowFilter {
  isActive: IMultiSelectOption<boolean> | null;
}
export interface IConnectFlowState extends IListable {
  filter: IConnectFlowFilter;
}

export enum ConnectFlowMutations {
  SET_SEARCH = "connectFlow_setSearch",
  SET_SORT = "connectFlow_setSort",
  SET_PAGE = "connectFlow_setPage",
  SET_FILTER = "connectFlow_setFilter",
  CLEAR_FILTER = "connectFlow_clearFilter",
}

export enum ConnectFlowActions {
  SET_SEARCH = "connectFlow_setSearch",
  SET_SORT = "connectFlow_setSort",
  SET_PAGE = "connectFlow_setPage",
  SET_FILTER = "connectFlow_setFilter",
  CLEAR_FILTER = "connectFlow_clearFilter",
}

export enum ConnectFlowGetters {
  SEARCH = "connectFlow_search",
  SORT = "connectFlow_sort",
  PAGE = "connectFlow_page",
  FILTER = "connectFlow_filter",
  DEFAULT_FILTER = "connectFlow_defaultFilter",
  ACTIVE_FILTERS = "connectFlow_activeFilters",
}
