import { IAgentProductivityPerDayReportServiceGetInfoResult } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class AgentProductivityPerDayReportService extends BaseService<IAgentProductivityPerDayReportServiceGetInfoResult> {
  getInfo(config?: AxiosRequestConfig) {
    return this.http.get<IAgentProductivityPerDayReportServiceGetInfoResult>(
      `${this.route}`,
      config,
    );
  }
}

export default new AgentProductivityPerDayReportService(
  "reports/agent-productivity-per-day",
);
