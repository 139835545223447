export enum Permission {
  VIEW_USERS = "viewUsers",
  VIEW_USER = "viewUser",
  CREATE_USER = "createUser",
  EDIT_USER = "editUser",
  VIEW_USER_LABELS = "viewUserLabels",
  VIEW_USER_LABEL = "viewUserLabel",
  CREATE_USER_LABEL = "createUserLabel",
  EDIT_USER_LABEL = "editUserLabel",
  VIEW_LEVELS = "viewLevels",
  VIEW_LEVEL = "viewLevel",
  CREATE_LEVEL = "createLevel",
  EDIT_LEVEL = "editLevel",
  VIEW_ROLE = "viewRole",
  CREATE_ROLE = "createRole",
  EDIT_ROLE = "editRole",
  VIEW_NUMBERS = "viewNumbers",
  VIEW_NUMBER = "viewNumber",
  EDIT_NUMBER = "editNumber",
  ASSIGN_NUMBER_TO_USER = "assignNumberToUser",
  ASSIGN_ROLE_TO_USER = "assignRoleToUser",
  ASSIGN_LEVEL_TO_USER = "assignLevelToUser",
  ASSIGN_PERMISSION_TO_ROLE = "assignPermissionToRole",
  VIEW_CALL_REPORT = "viewCallReport",
  VIEW_CONTACT_CENTER_POR_AGENT_REPORT = "viewContactCenterPerAgentReport",
  VIEW_CONTACT_CENTER_PER_DAY_REPORT = "viewContactCenterPerDayReport",
  VIEW_AGENT_PRODUCTIVITY_REPORT = "viewAgentProductivityPerAgentReport",
  VIEW_REAL_TIME_REPORT = "viewRealtimeReport",
  VIEW_QUEUE_TIME_REPORT = "viewQueueTimeReport",
  VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT = "viewAgentProductivityPerDayReport",
  VIEW_AGENT_DEVICE_STATUS_HISTORY_REPORT = "viewAgentDeviceStatusHistoryReport",
  VIEW_CALLS = "viewCalls",
  VIEW_CALL = "viewCall",
  VIEW_MEETINGS = "viewMeetings",
  VIEW_MEETING = "viewMeeting",
  VIEW_SPAM_NUMBERS = "viewSpamNumbers",
  VIEW_SPAM_NUMBER = "viewSpamNumber",
  CREATE_SPAM_NUMBER = "createSpamNumber",
  EDIT_SPAM_NUMBER = "editSpamNumber",
  VIEW_WORDS = "viewWords",
  VIEW_WORD = "viewWord",
  CREATE_WORD = "createWord",
  EDIT_WORD = "editWord",
  VIEW_CONNECT_FLOWS = "viewConnectFlows",
  VIEW_CONNECT_FLOW = "viewConnectFlow",
  CREATE_CONNECT_FLOW = "createConnectFlow",
  EDIT_CONNECT_FLOW = "editConnectFlow",
  VIEW_SCHEDULES = "viewSchedules",
  VIEW_SCHEDULE = "viewSchedule",
  CREATE_SCHEDULE = "createSchedule",
  EDIT_SCHEDULE = "editSchedule",
  VIEW_QUEUES = "viewQueues",
  VIEW_QUEUE = "viewQueue",
  CREATE_QUEUE = "createQueue",
  EDIT_QUEUE = "editQueue",
  VIEW_SURVEYS = "viewSurveys",
  VIEW_SURVEY = "viewSurvey",
  CREATE_SURVEY = "createSurvey",
  EDIT_SURVEY = "editSurvey",
  VIEW_CONNECT_FLOW_AUDIOS = "viewConnectFlowAudios",
  VIEW_CONNECT_FLOW_AUDIO = "viewConnectFlowAudio",
  CREATE_CONNECT_FLOW_AUDIO = "createConnectFlowAudio",
  EDIT_CONNECT_FLOW_AUDIO = "editConnectFlowAudio",
  VIEW_INTEGRATIONS = "viewIntegrations",
  VIEW_INTEGRATION_LOGS = "viewIntegrationLogs",
  EDIT_INTEGRATION = "editIntegration",
}
