import { IUser } from "./IUser";

export interface IMeeting {
  id: number;
  levelId: string;
  title: string;
  platform: string;
  speechDuration: number;
  externalId: string;
  participants: Array<IMeetingParticipant>;
  messages?: Array<IMeetingMessage>;
  createdAt: string;
  createdByUser?: IUser;
  updatedByUser?: IUser;
  updatedAt?: IUser;
}

export interface IMeetingMessage {
  id: number;
  meetingId: number;
  speakerName: string;
  transcript: string;
  deviceExternalId: string;
  messageExternalId: string;
  messageVersion: number;
  version: string;
  isPinned: boolean;
  timestamp: number;
  tags: object[];
  createdAt?: string;
}

export interface IMeetingMessageWithFormattedTimestamp extends IMeetingMessage {
  formattedTimestamp: string;
}

export interface IMeetingParticipant {
  id: number;
  meetingId: number;
  name: string;
  textLength: number;
}

export interface IParticipantWithSpeechPercentage extends IMeetingParticipant {
  speechPercentage: number;
}

export interface MeetingPlatformDisplayNames {
  [key: string]: string;
}

export enum PlatformDisplayNames {
  GOOGLE_MEET = "Google Meet",
  ZOOM = "Zoom",
  MICROSOFT_TEAMS = "Microsoft Teams",
  WEBEX = "Webex",
  BLUEJEANS = "BlueJeans",
}
