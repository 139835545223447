import { ILevel, IDefaultServiceResult } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class LevelService extends BaseService<ILevel> {
  getAllGlobalSelect<ResponseT = ILevel[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/global-select`, config);
  }
  activate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
  };
  inactivate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(
      `${this.route}/${id}/inactivate`,
    );
  };
}

export default new LevelService("levels");
