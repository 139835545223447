import posthog from "posthog-js";

let ph = null;
if (process.env.NODE_ENV === "production") {
  ph = posthog.init("phc_pfJW2resq4MYMyKFlVtd6lT8CKp1TipiG2KHUWzJ8Kj", {
    api_host: "https://app.posthog.com",
  });
}

export default ph;
