/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IIntegrationLogState,
  IIntegrationLogFilter,
  IntegrationLogMutations,
  IntegrationLogActions,
  IntegrationLogGetters,
  ISortMenuItem,
  IFilterItem,
} from "@/definitions";

const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      status: null,
    },
  } as IIntegrationLogState;
};

const state: IIntegrationLogState = getDefaultState();

const mutations: MutationTree<IIntegrationLogState> = {
  [IntegrationLogMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [IntegrationLogMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [IntegrationLogMutations.SET_PAGE]: (state, page: number) =>
    (state.page = page),
  [IntegrationLogMutations.SET_FILTER](state, payload: IIntegrationLogFilter) {
    state.filter.status = payload.status;
  },
  [IntegrationLogMutations.CLEAR_FILTER](state, field?: string) {
    switch (field) {
      case "status":
        state.filter.status = getDefaultState().filter.status;
        break;
      default:
        state.filter.status = getDefaultState().filter.status;
    }
  },
};

const actions: ActionTree<IIntegrationLogState, any> = {
  [IntegrationLogActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(IntegrationLogMutations.SET_SEARCH, search),
  [IntegrationLogActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(IntegrationLogMutations.SET_SORT, sort),
  [IntegrationLogActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(IntegrationLogMutations.SET_PAGE, page),
  [IntegrationLogActions.SET_FILTER](
    { commit },
    payload: IIntegrationLogFilter,
  ) {
    commit(IntegrationLogMutations.SET_FILTER, payload);
  },
  [IntegrationLogActions.CLEAR_FILTER]({ commit }, field?: string) {
    commit(IntegrationLogMutations.CLEAR_FILTER, field);
  },
};

const getters: GetterTree<IIntegrationLogState, any> = {
  [IntegrationLogGetters.SEARCH]: (state): string | null => state.search,
  [IntegrationLogGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [IntegrationLogGetters.PAGE]: (state): number => state.page,
  [IntegrationLogGetters.DEFAULT_FILTER]: (): IIntegrationLogFilter =>
    getDefaultState().filter,
  [IntegrationLogGetters.FILTER]: (state): IIntegrationLogFilter =>
    state.filter,
  [IntegrationLogGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    if (state.filter.status)
      filters.push({
        field: "status",
        value: state.filter.status.value,
        label: state.filter.status.label,
      });

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
