import { ICall } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class RealtimeReportService<T> extends BaseService<ICall> {
  getDataTotalize<T>(config?: AxiosRequestConfig) {
    return this.http.get<T>(`${this.route}`, config);
  }
  getAgentExtensions<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/agent-extensions`, config);
  }
  getQueueCalls<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/queue-calls`, config);
  }
}

export default new RealtimeReportService("reports/realtime");
