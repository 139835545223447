import { App } from "vue";
import { can } from "@/utils/auth";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import analytics from "@/utils/analytics";
import { IAnalytics, Permission } from "@/definitions";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $window: Window;
    $can: (permission: Permission) => boolean;
    $analytics: IAnalytics | void;
  }
}

const globalProperties = {
  install(app: App<Element>) {
    app.config.globalProperties.$window = window;
    app.config.globalProperties.$can = can;
    if (process.env.NODE_ENV === "production") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      app.config.globalProperties.$analytics = analytics;
    }
  },
};

export default globalProperties;
