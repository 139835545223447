import { IListable } from "@/definitions";

export interface IConnectFlowAudioFilter {
  isActive: boolean | null;
}
export interface IConnectFlowAudioState extends IListable {
  filter: IConnectFlowAudioFilter;
}

export enum AudioMutations {
  SET_SEARCH = "connectFlowAudio_setSearch",
  SET_SORT = "connectFlowAudio_setSort",
  SET_PAGE = "connectFlowAudio_setPage",
  SET_FILTER = "connectFlowAudio_setFilter",
  CLEAR_FILTER = "connectFlowAudio_clearFilter",
}

export enum AudioActions {
  SET_SEARCH = "connectFlowAudio_setSearch",
  SET_SORT = "connectFlowAudio_setSort",
  SET_PAGE = "connectFlowAudio_setPage",
  SET_FILTER = "connectFlowAudio_setFilter",
  CLEAR_FILTER = "connectFlowAudio_clearFilter",
}

export enum AudioGetters {
  SEARCH = "connectFlowAudio_search",
  SORT = "connectFlowAudio_sort",
  PAGE = "connectFlowAudio_page",
  FILTER = "connectFlowAudio_filter",
}
