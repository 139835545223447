/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  ISurveyState,
  SurveyMutations,
  SurveyActions,
  SurveyGetters,
  ISurveyFilter,
  ISortMenuItem,
} from "@/definitions";
const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      isActive: null,
    },
  } as ISurveyState;
};

const state: ISurveyState = getDefaultState();

const mutations: MutationTree<ISurveyState> = {
  [SurveyMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [SurveyMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [SurveyMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [SurveyMutations.SET_FILTER](state, filter: ISurveyFilter) {
    state.filter.isActive = filter.isActive;
  },
  [SurveyMutations.CLEAR_FILTER](state, field?: string) {
    // This makes no sense but ok...
    switch (field) {
      case "isActive":
        state.filter.isActive = getDefaultState().filter.isActive;
        break;
      default:
        state.filter.isActive = getDefaultState().filter.isActive;
    }
  },
};

const actions: ActionTree<ISurveyState, any> = {
  [SurveyActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(SurveyMutations.SET_SEARCH, search),
  [SurveyActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(SurveyMutations.SET_SORT, sort),
  [SurveyActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(SurveyMutations.SET_PAGE, page),
  [SurveyActions.SET_FILTER]: ({ commit }, filter: ISurveyFilter) =>
    commit(SurveyMutations.SET_FILTER, filter),
  [SurveyActions.CLEAR_FILTER]: ({ commit }, filter: ISurveyFilter) =>
    commit(SurveyMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<ISurveyState, any> = {
  [SurveyGetters.SEARCH]: (state): string | null => state.search,
  [SurveyGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [SurveyGetters.PAGE]: (state): number => state.page,
  [SurveyGetters.FILTER]: (state): ISurveyFilter => state.filter,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
