import { IListable } from "@/definitions";

export interface IScheduleFilter {
  isActive: boolean | null;
}
export interface IScheduleState extends IListable {
  filter: IScheduleFilter;
}

export enum ScheduleMutations {
  SET_SEARCH = "level_setSearch",
  SET_SORT = "level_setSort",
  SET_PAGE = "level_setPage",
  SET_FILTER = "level_setFilter",
  CLEAR_FILTER = "level_clearFilter",
}

export enum ScheduleActions {
  SET_SEARCH = "level_setSearch",
  SET_SORT = "level_setSort",
  SET_PAGE = "level_setPage",
  SET_FILTER = "level_setFilter",
  CLEAR_FILTER = "level_clearFilter",
}

export enum ScheduleGetters {
  SEARCH = "level_search",
  SORT = "level_sort",
  PAGE = "level_page",
  FILTER = "level_filter",
}
