import store from "@/store";
import AuthService from "@/services/AuthService";
import {
  NavigationGuardNext,
  NavigationGuardWithThis,
  RouteLocationNormalized,
} from "vue-router";
import { isPositiveInteger } from "@/utils/number";
import { AuthActions, AuthGetters, IntegrationType } from "@/definitions";

export const beforeEach: NavigationGuardWithThis<undefined> = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const noCheck = ["/app/login", "/update-password"];

  if (store.getters[AuthGetters.USER] === undefined) {
    if (noCheck.indexOf(to.path) != -1) {
      next();
    } else if (to.path != "/login") {
      AuthService.check().then(
        async (response) => {
          if (response.data.user.lastLogin === null) {
            next({ name: "UpdatePassword" });
          }
          await store.dispatch(AuthActions.LOGIN, response.data);
          next();
        },
        () => next({ name: "Login" }),
      );
    } else if (to.path === "/login") {
      AuthService.check().then(
        async (response) => {
          if (response.data.user.lastLogin === null) {
            next({ name: "UpdatePassword" });
          } else {
            await store.dispatch(AuthActions.LOGIN, response.data);
            next({ name: "Home" });
          }
        },
        () => next(),
      );
    }
  } else {
    next();
  }
};

export const idIsPositiveInteger: NavigationGuardWithThis<undefined> = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const id: string | string[] = to.params.id;
  if (typeof id === "string" && isPositiveInteger(id)) next();
  else next("not-found");
};

export const typeIsValidIntegrationType: NavigationGuardWithThis<undefined> = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const type: string | string[] = to.params.type;

  if (
    typeof type === "string" &&
    Object.values(IntegrationType).includes(type as IntegrationType)
  ) {
    next();
  } else {
    next("not-found");
  }
};
