import axios from "axios";
import store from "@/store";
import { FilterGetters } from "@/definitions";

const baseURL = ["production", "staging"].includes(process.env.NODE_ENV)
  ? "/api"
  : "http://localhost:3000/api";

const instance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers.authorization =
      "Bearer " + localStorage.getItem("token") ?? "";
    config.headers["Accept-Language"] = localStorage.getItem("locale") ?? "";

    let level = store.getters[FilterGetters.LEVEL];
    if (level === null) {
      const storageLevel = localStorage.getItem("level");
      if (storageLevel !== null) {
        level = JSON.parse(atob(storageLevel));
      }
    }
    config.headers["level"] = level?.id;
    return config;
  },
  (error) => Promise.reject(error),
);

export default instance;
