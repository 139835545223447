import {
  IParticipantWithSpeechPercentage,
  IMeetingMessage,
  IMeetingMessageWithFormattedTimestamp,
} from "../definitions";

/**
 * Calcula a porcentagem de fala de cada participante em relação ao total de texto falado na reunião.
 *
 * @param {IParticipantWithSpeechPercentage[]} participants - Lista de participantes com a propriedade textLength.
 * @returns {IParticipantWithSpeechPercentage[]} - Lista de participantes com a porcentagem de fala calculada.
 */
export function calculateSpeechPercentage(
  participants: IParticipantWithSpeechPercentage[],
): IParticipantWithSpeechPercentage[] {
  const totalTextLength = participants.reduce(
    (total, participant) => total + participant.textLength,
    0,
  );

  if (totalTextLength === 0) {
    return participants.map((participant) => ({
      ...participant,
      speechPercentage: 0.0,
    }));
  }

  let speechPercentages = participants.map((participant) => ({
    ...participant,
    speechPercentage: parseFloat(
      ((participant.textLength / totalTextLength) * 100).toFixed(0),
    ),
  }));

  const totalPercentage = speechPercentages.reduce(
    (total, participant) => total + participant.speechPercentage,
    0,
  );

  const difference = totalPercentage - 100;
  if (difference !== 0) {
    speechPercentages = speechPercentages.map((participant, index) => {
      if (index === 0) {
        return {
          ...participant,
          speechPercentage: participant.speechPercentage - difference,
        };
      }
      return participant;
    });
  }

  return speechPercentages;
}

/**
 * Formata as mensagens da reunião com o tempo decorrido desde o início da reunião.
 *
 * @param {IMeetingMessage[]} messages - Lista de mensagens da reunião.
 * @returns {IMeetingMessageWithFormattedTimestamp[]} - Lista de mensagens com timestamp formatado.
 */
export function formatMessagesWithElapsedTime(
  messages: IMeetingMessage[],
): IMeetingMessageWithFormattedTimestamp[] {
  const startTime = messages.length > 0 ? Number(messages[0].timestamp) : 0;

  return messages.map((message) => ({
    ...message,
    formattedTimestamp: formatElapsedTime(
      Number(message.timestamp) - startTime,
    ),
  }));
}

/**
 * Formata o tempo decorrido em horas, minutos e segundos.
 *
 * @param {number} elapsedTime - Tempo decorrido em milissegundos.
 * @returns {string} - Tempo formatado como string.
 */
function formatElapsedTime(elapsedTime: number): string {
  const totalSeconds = Math.floor(elapsedTime / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}

export function formatDateTime(dateString: string | Date): string {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);
  return date.toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}
