/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IConnectFlowFlow,
  IConnectFlowModelingMenu,
  IConnectFlowModelingState,
  IFlowConnection,
  IFlowNode,
  NodeType,
  EdgeType,
  ISchedule,
  IQueue,
  IConnectFlow,
  IUserExtensions,
  IConnectFlowAudio,
  ISurvey,
} from "@/definitions";
import cloneDeep from "lodash/cloneDeep";
import NodesRules, { INodeRules } from "@/definitions/FlowNodeRules";
import {
  ConnectFlowAudioService,
  ConnectFlowService,
  QueueService,
  ScheduleService,
  SurveyService,
} from "@/services";
import { AxiosResponse } from "axios";
import { toastServiceError } from "@/utils/notification";
import { MarkerType } from "@vue-flow/core";
import store from "@/store";
import { i18n } from "@/utils/locale";
import UserExtensionsService from "@/services/UserExtensionsService";

const getDefaultState = () => {
  return {
    elements: [
      {
        id: "1",
        type: "input",
        parentId: "0",
        data: { component: NodeType.START },
        draggable: false,
        selectable: false,
        connectable: true,
        position: { x: 0, y: 300 },
      },
    ],
    menu: {
      show: false,
      action: "add",
      idTriggerBy: null,
    },
    scheduleOptions: [] as ISchedule[],
    queueOptions: [] as IQueue[],
    extensionsOptions: [] as IUserExtensions[],
    audioOptions: [] as IConnectFlowAudio[],
    surveyOptions: [] as ISurvey[],
  } as IConnectFlowModelingState;
};

const state: IConnectFlowModelingState = getDefaultState();

const mutations: MutationTree<IConnectFlowModelingState> = {
  [ConnectFlowModelingMutations.SET_MENU]: (
    state,
    value: { show: boolean; action: string },
  ) => {
    state.menu.show = value.show;
    state.menu.action = value.action ?? "add";
  },
  [ConnectFlowModelingMutations.SET_MENU_TRIGGER_BY]: (
    state,
    value: string,
  ) => {
    state.menu.idTriggerBy = value;
  },
  [ConnectFlowModelingMutations.PUSH_NEW_ELEMENT]: (
    state,
    value: IFlowNode | IFlowConnection,
  ) => state.elements.push(value),
  [ConnectFlowModelingMutations.SET_ELEMENTS]: (
    state,
    value: (IFlowNode | IFlowConnection)[],
  ) => (state.elements = value),
  [ConnectFlowModelingMutations.SET_CONNECT_FLOW]: (
    state,
    value: IConnectFlow,
  ) => (state.connectFlow = value),
  [ConnectFlowModelingMutations.SET_SCHEDULE]: (state, value: ISchedule[]) =>
    (state.scheduleOptions = value),
  [ConnectFlowModelingMutations.SET_QUEUE]: (state, value: IQueue[]) =>
    (state.queueOptions = value),
  [ConnectFlowModelingMutations.SET_SURVEY]: (state, value: ISurvey[]) =>
    (state.surveyOptions = value),
  [ConnectFlowModelingMutations.SET_AUDIO]: (
    state,
    value: IConnectFlowAudio[],
  ) => (state.audioOptions = value),
  [ConnectFlowModelingMutations.SET_EXTENSIONS]: (
    state,
    value: IUserExtensions[],
  ) => (state.extensionsOptions = value),
  [ConnectFlowModelingMutations.UPDATE_ELEMENT]: (
    state,
    value: { idx: number | null; value: IFlowNode | IFlowConnection },
  ) => {
    let idx = value.idx;
    if (idx === null) {
      idx = state.elements.findIndex((e) => e.id === value.value.id);
    }
    state.elements[idx] = value.value;
  },
};

const actions: ActionTree<IConnectFlowModelingState, any> = {
  [ConnectFlowModelingActions.SET_MENU]: (
    { commit },
    value: {
      show: boolean;
      action: string;
      idTriggerBy: string;
    },
  ) => {
    commit(ConnectFlowModelingMutations.SET_MENU, {
      show: value.show,
      action: value.action,
    });
    if (value.idTriggerBy !== undefined) {
      commit(
        ConnectFlowModelingMutations.SET_MENU_TRIGGER_BY,
        value.idTriggerBy,
      );
    }
  },
  [ConnectFlowModelingActions.SET_ELEMENTS]: (
    { commit },
    value: (IFlowNode | IFlowConnection)[],
  ) => commit(ConnectFlowModelingMutations.SET_ELEMENTS, value),
  [ConnectFlowModelingActions.SET_CONNECT_FLOW]: (
    { commit },
    value: IConnectFlowFlow,
  ) => commit(ConnectFlowModelingMutations.SET_CONNECT_FLOW, value),
  [ConnectFlowModelingActions.EDIT_NODE]: async (
    { getters, state },
    newNode: IFlowNode,
  ) => {
    const parentNode: IFlowNode = getters[
      ConnectFlowModelingGetters.ELEMENTS
    ].find((n: IFlowNode) => n.id === state.menu.idTriggerBy);

    if (parentNode === undefined) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newNodeRules = NodesRules[newNode.data.component];
    parentNode.connectable = newNodeRules.maxChildren > 0;
    parentNode.data.component = newNode.data.component;
    parentNode.data.hasErrors = false;
    await insertAutoChildren(newNodeRules, parentNode);
  },
  [ConnectFlowModelingActions.PUSH_NEW_NODE]: async (
    { commit, getters, state },
    newNode: IFlowNode,
  ) => {
    const parentNode: IFlowNode = getters[
      ConnectFlowModelingGetters.ELEMENTS
    ].find((n: IFlowNode) => n.id === state.menu.idTriggerBy);

    if (parentNode === undefined) {
      console.log("OH NO! Daddy wasn't found");
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newNodeRules = NodesRules[newNode.data.component];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parentNodeRules = NodesRules[parentNode.data.component];

    const siblings = getters[ConnectFlowModelingGetters.ELEMENTS_NODES].filter(
      (n: IFlowNode) => n.parentId === parentNode.id,
    );
    parentNode.connectable = parentNodeRules.maxChildren > siblings.length + 1;
    newNode.connectable = newNodeRules.maxChildren > 0;

    let xDeviation = 0;
    let yDeviation = 200;
    let x = parentNode.position.x;
    let y = parentNode.position.y;
    const lastItem = siblings.length - 1;

    if (newNode?.position?.x !== undefined) {
      xDeviation = newNode.position.x;
    } else if (newNodeRules?.position?.x !== undefined) {
      xDeviation = newNodeRules.position.x;
    }

    if (newNode?.position?.y !== undefined) {
      yDeviation = newNode.position.y;
    } else if (newNodeRules?.position?.y !== undefined) {
      yDeviation = newNodeRules.position.y;
    }

    if (newNode?.position?.y !== undefined) {
      yDeviation = newNode.position.y;
    }

    if (lastItem >= 2) {
      x = siblings[lastItem].position.x;
      y = siblings[lastItem].position.y;
      xDeviation = 270;
      yDeviation = 0;
    }

    newNode.position = {
      x: x + xDeviation,
      y: y + yDeviation,
    };

    const newNodeId = generateRandomId();
    newNode.id = newNodeId;
    newNode.parentId = parentNode.id;
    const connection: IFlowConnection = {
      id: `e${generateRandomId()}`,
      source: parentNode.id,
      target: newNodeId,
      type: "custom",
      markerEnd: MarkerType.ArrowClosed,
    };

    if (newNode.sourceHandle !== undefined) {
      connection.sourceHandle = newNode.sourceHandle;
    }

    if (
      parentNode.data.component === NodeType.CLIENT_INFO &&
      newNode.data.component !== NodeType.OPEN_EDGE
    ) {
      connection.data = {
        component: EdgeType.CLIENT_INFO_EDGE,
        waitUser: false,
        uraOption: null,
        hasErrors: true,
      };
    }

    if (parentNode.data.component === NodeType.START) {
      connection.data = {
        component: EdgeType.AWAITABLE,
        waitUser: false,
        uraOption: null,
        hasErrors: false,
      };
    }

    if (connection.data === undefined) {
      connection.data = {
        component: EdgeType.NORMAL,
        waitUser: false,
        uraOption: null,
        hasErrors: false,
      };
    }

    if (newNode.data?.flowType) {
      connection.data.flowType = newNode.data?.flowType;
    }

    if (newNode.data.component === NodeType.OPEN_EDGE && newNode.data?.title) {
      connection.label = i18n.global.t(newNode.data?.title);
      newNode.data.hasErrors = true;
    } else {
      newNode.data.hasErrors = false;
    }

    commit(ConnectFlowModelingMutations.PUSH_NEW_ELEMENT, newNode);
    commit(ConnectFlowModelingMutations.PUSH_NEW_ELEMENT, connection);

    await insertAutoChildren(newNodeRules, newNode);
  },
  [ConnectFlowModelingActions.DELETE_NODE]: async (
    { commit, state, getters },
    params: { nodeId: string; parentId: string },
  ) => {
    const elementsToRemove = findFamilyElements(state.elements, params.nodeId);

    const elements = state.elements.filter((n: IFlowNode | IFlowConnection) => {
      return !elementsToRemove.includes(n.id);
    });
    commit(ConnectFlowModelingMutations.SET_ELEMENTS, elements);

    const parentNode = getters[ConnectFlowModelingGetters.ELEMENTS_NODES].find(
      (node: IFlowNode) => {
        return node.id === params.parentId;
      },
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const nodeRules: INodeRules = NodesRules[parentNode.data.component];
    const childrenCount = getters[
      ConnectFlowModelingGetters.ELEMENTS_NODES
    ].filter((n: IFlowNode) => n.parentId === parentNode.id).length;
    parentNode.connectable = nodeRules.maxChildren > childrenCount;

    if (parentNode.connectable && nodeRules.autoChildren.length) {
      const nodeConnections: IFlowConnection[] = getters[
        ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS
      ].filter((c: IFlowConnection) => c.source === parentNode.id);

      insertAutoChildren(nodeRules, parentNode, nodeConnections).then();
    }
  },
  [ConnectFlowModelingActions.GO_TO_CONNECTION]: async (
    { getters, commit, dispatch },
    goTo: { source: string; target: string; handle: string },
  ) => {
    const oldConnection = getters[ConnectFlowModelingGetters.ELEMENTS].find(
      (conn: IFlowConnection) => conn.source === goTo.source,
    );

    if (oldConnection) {
      await dispatch(ConnectFlowModelingActions.DELETE_NODE, oldConnection.id);
    }

    commit(ConnectFlowModelingMutations.PUSH_NEW_ELEMENT, {
      id: `e${generateRandomId()}`,
      source: goTo.source,
      target: goTo.target,
      type: "smoothstep",
      targetHandle: goTo.handle,
      markerEnd: MarkerType.ArrowClosed,
    });
  },
  [ConnectFlowModelingActions.LOAD_FLOW_FLOW]: async (
    { commit },
    params: { id: number; historyId?: number },
  ) => {
    const promises = [
      await ScheduleService.getAll<Array<ISchedule>>({
        params: { is_active: true },
      }),
      await QueueService.getAll<Array<IQueue>>({
        params: { is_active: true },
      }),
      await SurveyService.getAll<Array<ISurvey>>({
        params: { is_active: true },
      }),
      await UserExtensionsService.getAll<IUserExtensions[]>({
        params: { is_active: true },
      }),
      await ConnectFlowAudioService.getAll<IConnectFlowAudio[]>({
        params: { is_active: true },
      }),
    ];

    Promise.all(promises)
      .then((responses) => {
        const [
          scheduleResponse,
          queueResponse,
          surveyResponse,
          extensionsResponse,
          audioResponse,
        ] = responses;

        commit(
          ConnectFlowModelingMutations.SET_SCHEDULE,
          scheduleResponse.data,
        );
        commit(ConnectFlowModelingMutations.SET_QUEUE, queueResponse.data);
        commit(ConnectFlowModelingMutations.SET_SURVEY, surveyResponse.data);
        commit(
          ConnectFlowModelingMutations.SET_EXTENSIONS,
          extensionsResponse.data,
        );
        commit(ConnectFlowModelingMutations.SET_AUDIO, audioResponse.data);
      })
      .catch((error) => {
        console.log(error);
        return;
      });

    ConnectFlowService.getFlow(params.id, params.historyId).then(
      (response: AxiosResponse<IConnectFlowFlow>) => {
        if (response.data.flow !== undefined) {
          commit(
            ConnectFlowModelingMutations.SET_CONNECT_FLOW,
            response.data.connectFlow,
          );
          commit(ConnectFlowModelingMutations.SET_ELEMENTS, response.data.flow);
        } else {
          commit(
            ConnectFlowModelingMutations.SET_ELEMENTS,
            getDefaultState().elements,
          );
        }
      },
      toastServiceError,
    );
  },
};

const getters: GetterTree<IConnectFlowModelingState, any> = {
  [ConnectFlowModelingGetters.MENU]: (state): IConnectFlowModelingMenu =>
    state.menu,
  [ConnectFlowModelingGetters.CONNECT_FLOW]: (state) => state.connectFlow,
  [ConnectFlowModelingGetters.ELEMENTS]: (state) => state.elements,
  [ConnectFlowModelingGetters.ELEMENT_BY_ID]: (state) => (nodeID: string) =>
    state.elements.find((n) => n.id === nodeID),
  [ConnectFlowModelingGetters.ELEMENTS_NODES]: (
    state: IConnectFlowModelingState,
  ): IFlowNode[] => state.elements.filter(isFlowNode) as IFlowNode[],
  [ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS]: (
    state: IConnectFlowModelingState,
  ): IFlowConnection[] =>
    state.elements.filter(isFlowConnection) as IFlowConnection[],
  [ConnectFlowModelingGetters.IS_ALL_ELEMENTS_VALID]: (
    state: IConnectFlowModelingState,
  ): boolean =>
    state.elements.filter((e) => e.data !== undefined && e.data?.hasErrors)
      .length === 0,
  [ConnectFlowModelingGetters.SCHEDULE_OPTIONS]: (
    state: IConnectFlowModelingState,
  ): ISchedule[] => state.scheduleOptions,
  [ConnectFlowModelingGetters.AUDIO_OPTIONS]: (
    state: IConnectFlowModelingState,
  ): IConnectFlowAudio[] => state.audioOptions,
  [ConnectFlowModelingGetters.QUEUE_OPTIONS]: (
    state: IConnectFlowModelingState,
  ): IQueue[] => state.queueOptions,
  [ConnectFlowModelingGetters.SURVEY_OPTIONS]: (
    state: IConnectFlowModelingState,
  ): ISurvey[] => state.surveyOptions,
  [ConnectFlowModelingGetters.EXTENSION_OPTIONS]: (
    state: IConnectFlowModelingState,
  ): IUserExtensions[] => state.extensionsOptions,
};

function generateRandomId() {
  return Math.random().toString(36).substring(2, 6);
}

async function insertAutoChildren(
  nodeRules: INodeRules,
  node: IFlowNode,
  connections: IFlowConnection[] = [],
) {
  if (nodeRules.autoChildren.length) {
    store.commit(
      "flow/" + ConnectFlowModelingMutations.SET_MENU_TRIGGER_BY,
      node.id,
    );
    for (const child of nodeRules.autoChildren) {
      const existingConnection = connections.filter((c) => {
        return c.data !== undefined && c.data.flowType === child.data.flowType;
      });
      if (existingConnection.length === 0) {
        await store.dispatch(
          "flow/" + ConnectFlowModelingActions.PUSH_NEW_NODE,
          cloneDeep(child),
        );
      }
    }
  }
}

function findFamilyElements(
  elements: (IFlowNode | IFlowConnection)[],
  nodeId: string,
  result: string[] = [],
): string[] {
  result.push(nodeId);
  for (const e of elements) {
    if (
      isFlowConnection(e) &&
      e.id !== undefined &&
      (e.source === nodeId || e.target === nodeId)
    ) {
      result.push(e.id);
    }

    if (isFlowNode(e) && e.parentId === nodeId) {
      result.push(e.id);
      findFamilyElements(elements, e.id, result);
    }
  }
  return result;
}

function isFlowNode(
  element: IFlowNode | IFlowConnection,
): element is IFlowNode {
  return (
    (element as IFlowNode).parentId !== undefined &&
    (element as IFlowNode).position !== undefined
  );
}

function isFlowConnection(
  element: IFlowNode | IFlowConnection,
): element is IFlowConnection {
  return (
    (element as IFlowConnection).source !== undefined &&
    (element as IFlowConnection).target !== undefined
  );
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
