import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { beforeEach } from "@/router/routeGuards";
import AppTemplate from "@/views/templates/AppTemplate.vue";
import ConfigTemplate from "@/views/templates/ConfigTemplate.vue";
import HomePage from "@/views/HomePage.vue";
import ConfigurationPage from "@/views/ConfigurationPage.vue";
import ProfilePage from "@/views/pages/profile/ProfilePage.vue";
import PermissionPage from "@/views/pages/permission/PermissionPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import AuthenticationRoutes from "@/router/modules/authentication";
import UserRoutes from "@/router/modules/user";
import NumberRoutes from "@/router/modules/number";
import SpamNumberRoutes from "@/router/modules/spamNumber";
import RoleRoutes from "@/router/modules/role";
import LevelRoutes from "@/router/modules/level";
import ScheduleRoutes from "@/router/modules/schedule";
import ConnectFlowAudioRoutes from "@/router/modules/connectFlowAudio";
import QueueRoutes from "@/router/modules/queue";
import SurveyRoutes from "@/router/modules/survey";
import CallRoutes from "@/router/modules/call";
import MeetingRoutes from "@/router/modules/meeting";
import WordRouter from "@/router/modules/word";
import UserLabel from "@/router/modules/userLabel";
import ReportRoutes from "@/router/modules/report";
import ConnectFlowRoutes from "@/router/modules/connectFlow";
import IntegrationRoutes from "@/router/modules/integration";

const routes: RouteRecordRaw[] = AuthenticationRoutes;
routes.push({
  name: "AppTemplate",
  path: "/",
  component: AppTemplate,
  children: [
    {
      name: "Home",
      path: "",
      component: HomePage,
    },
    {
      name: "Profile",
      path: "/profile",
      component: ProfilePage,
    },
    ReportRoutes,
    CallRoutes,
    MeetingRoutes,
    {
      path: "/:catchAll(.*)",
      component: NotFoundPage,
    },
  ],
});
routes.push({
  name: "ConfigTemplate",
  path: "/config",
  component: ConfigTemplate,
  children: [
    {
      name: "Configurations",
      path: "",
      component: ConfigurationPage,
    },
    {
      name: "Permissions",
      path: "permissions",
      component: PermissionPage,
    },
    UserRoutes,
    NumberRoutes,
    SpamNumberRoutes,
    LevelRoutes,
    ScheduleRoutes,
    QueueRoutes,
    SurveyRoutes,
    ConnectFlowAudioRoutes,
    RoleRoutes,
    WordRouter,
    UserLabel,
    ConnectFlowRoutes,
    IntegrationRoutes,
    {
      path: "/:catchAll(.*)",
      component: NotFoundPage,
    },
  ],
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(beforeEach);

export default router;
