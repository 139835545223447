import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import UserPage from "@/views/pages/user/UserPage.vue";
import UserDetailPage from "@/views/pages/user/UserDetailPage.vue";
import UserFormPage from "@/views/pages/user/UserFormPage.vue";
import { FormAction } from "@/definitions";

const userRouter: RouteRecordRaw = {
  name: "User",
  path: "user",
  children: [
    {
      name: "ListUser",
      path: "",
      component: UserPage,
    },
    {
      name: "ViewUserById",
      path: ":id",
      component: UserDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditUserById",
      path: ":id/edit",
      component: UserFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateUser",
      path: "create",
      component: UserFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};

export default userRouter;
