import { IListable, IMultiSelectOption } from "@/definitions";

export interface IUserLabelFilter {
  isActive: IMultiSelectOption | null;
}

export interface IUserLabelState extends IListable {
  filter: IUserLabelFilter;
}

export enum UserLabelMutations {
  SET_SEARCH = "userLabel_setSearch",
  SET_SORT = "userLabel_setSort",
  SET_PAGE = "userLabel_setPage",
  SET_FILTER = "userLabel_filter_setFilter",
  CLEAR_FILTER = "userLabel_filter_clearFilter",
}

export enum UserLabelActions {
  SET_SEARCH = "userLabel_setSearch",
  SET_SORT = "userLabel_setSort",
  SET_PAGE = "userLabel_setPage",
  SET_FILTER = "userLabel_filter_SetFilter",
  CLEAR_FILTER = "userLabel_filter_ClearFilter",
}

export enum UserLabelGetters {
  SEARCH = "userLabel_search",
  SORT = "userLabel_sort",
  PAGE = "userLabel_page",
  DEFAULT_FILTER = "userLabel_default_filter",
  FILTER = "userLabel_filter",
  ACTIVE_FILTERS = "userLabel_active_filters",
}
