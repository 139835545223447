import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import { FormAction } from "@/definitions";
import QueuePage from "@/views/pages/queue/QueuePage.vue";
import QueueDetailPage from "@/views/pages/queue/QueueDetailPage.vue";
import QueueFormPage from "@/views/pages/queue/QueueFormPage.vue";

const queueRouter: RouteRecordRaw = {
  name: "Queue",
  path: "queue",
  children: [
    {
      name: "ListQueue",
      path: "",
      component: QueuePage,
    },
    {
      name: "ViewQueueById",
      path: ":id",
      component: QueueDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditQueueById",
      path: ":id/edit",
      component: QueueFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateQueue",
      path: "create",
      component: QueueFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};
export default queueRouter;
