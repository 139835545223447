import { IUser } from "./IUser";

export interface IQueue {
  id: number;
  levelId: number;
  name: string;
  strategy: string;
  fileAudio: string;
  isActive: boolean;
  users?: IUser[];
  queueUsers?: IQueueUser[];
  createdBy?: IUser;
  createdAt?: string;
  updatedBy?: number;
  updatedAt?: string;
  createdByUser?: IUser;
  updatedByUser?: IUser;
}

export interface IQueueUser {
  userId: number;
  queueId: number;
}

export interface IQueueForm {
  id: number;
  levelId: number;
  name: string;
  strategy: string;
  fileAudio: string;
  isActive: boolean;
  users: number[];
  audio?: File | null;
}

export enum QueueStrategy {
  RINGALL = "ringall",
  LEASTRECENT = "leastrecent",
  FEWESTCALLS = "fewestcalls",
  RANDOM = "random",
  RRMEMORY = "rrmemory",
  RRORDERED = "rrordered",
}
