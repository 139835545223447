import {
  IUser,
  IDefaultServiceResult,
  IExtensionFormState,
  IUserServiceUpdatePasswordBody,
  IAuthServiceLoginResult,
} from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class UserService extends BaseService<IUser> {
  activate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
  };
  inactivate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(
      `${this.route}/${id}/inactivate`,
    );
  };
  async checkExtensionNumber(
    siblings: IExtensionFormState,
    config?: AxiosRequestConfig,
  ) {
    if (siblings.number !== null && siblings.levelId !== null) {
      const { data } = await this.http.post(
        `${this.route}/is-number-extension-available`,
        siblings,
        config,
      );
      return data;
    } else {
      return true;
    }
  }
  async firstLogin(loginBody: IUserServiceUpdatePasswordBody) {
    return this.http.put<IAuthServiceLoginResult>(
      `${this.route}/first-login`,
      loginBody,
    );
  }
  async resetPassword(loginBody: IUserServiceUpdatePasswordBody) {
    return this.http.put<IAuthServiceLoginResult>(
      `${this.route}/reset-password`,
      loginBody,
    );
  }
  async recoveryPassword(loginBody: IUserServiceUpdatePasswordBody) {
    return this.http.post<IAuthServiceLoginResult>(
      `${this.route}/recovery-password`,
      loginBody,
    );
  }
  async getCsvReport<ResponseT = Blob>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/report/csv`, config);
  }
}

export default new UserService("users");
