// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WaveSurfer from "wavesurfer.js";
export interface IAudioPlayerState {
  instance: WaveSurfer | null;
  playerReady: boolean;
  playerError: string | null;
  secondsDuration: number;
  secondsCurrent: number;
  loadingPercentage: number;
}

export enum AudioPlayerGetters {
  INSTANCE = "audioPlayer_instance",
  PLAYER_READY = "audioPlayer_player_ready",
  PLAYER_ERROR = "audioPlayer_player_error",
  SECONDS_DURATION = "audioPlayer_seconds_duration",
  SECONDS_CURRENT = "audioPlayer_seconds_current",
  LOADING_PERCENTAGE = "audioPlayer_loading_percentage",
}
export enum AudioPlayerMutations {
  SET_INSTANCE = "audioPlayer_set_instance",
  UNSET_INSTANCE = "audioPlayer_unset_instance",
  PLAYER_READY = "audioPlayer_player_ready",
  SET_PLAYER_ERROR = "audioPlayer_set_player_error",
  SET_SECONDS_DURATION = "audioPlayer_set_seconds_duration",
  SET_SECONDS_CURRENT = "audioPlayer_set_seconds_current",
  SET_LOADING_PERCENTAGE = "audioPlayer_set_loading_percentage",
}
export enum AudioPlayerActions {
  INIT_INSTANCE = "audioPlayer_init_instance",
  PLAY = "audioPlayer_play",
  PLAY_SNIPPET = "audioPlayer_play_snippet",
  PLAYER_READY = "audioPlayer_player_ready",
}
