import type { IRole, ILevel, INumber } from "@/definitions";

export interface IUser {
  id: number;
  name: string;
  isActive: boolean;
  email: string;
  lastLogin?: string;
  avatarUrl?: string;
  language?: string;
  standardLevelId?: number;
  hasMeeting?: boolean;
  createdBy?: number;
  createdAt?: string;
  updatedBy?: number;
  updatedAt?: string;
  createdByUser?: IUser;
  updatedByUser?: IUser;
  integrations: IUserIntegration[];
  currentLevel?: ILevel;
  roles?: IRole[];
  levels?: ILevel[];
  number?: IUserNumber;
  numbersModel?: INumber;
  devices: {
    mobile: boolean;
    desktop: boolean;
    web: boolean;
    external: boolean;
  };
  deviceExternal?: {
    hostname: string;
    sipUser: string;
    sipPassword: string;
  };
  extension: IUserExtensions;
}

export interface IUserNumber {
  id: number;
  levelId: number;
  numberId: number;
  level: ILevel;
  number: INumber;
  createdBy?: IUser;
  createdAt?: string;
}

export interface IUserExtensions {
  id: number;
  levelId: number;
  userId: number;
  label: string;
  number: number;
  toDelete: boolean;
  createdBy?: number;
  createdAt?: string;
  deletedBy?: number;
  deletedAt?: string;
  updatedBy?: number;
  updatedAt?: string;
  user?: IUser;
  createdByUser?: IUser;
  updatedByUser?: IUser;
  deletedByUser?: IUser;
}

export enum UserDevice {
  MOBILE = "mobile",
  DESKTOP = "desktop",
  WEB = "web",
  EXTERNAL = "external",
}

export const UserDeviceStatus = {
  OFFLINE: "OFFLINE",
  CONNECTED: "CONNECTED",
  DIALING: "DIALING",
  RING: "RING",
  RINGING: "RINGING",
  INUSE: "INUSE",
  IN_PAUSE: "IN_PAUSE",
  IN_CALL: "IN_CALL",
  UNAVAILABLE: "UNAVAILABLE",
};

export const UserExtensionQueueStatus = {
  PAUSED: "PAUSED",
  AVAILABLE: "AVAILABLE",
};

export interface IUserIntegration {
  id: number;
  userId: number;
  levelId: number;
  integration: string;
  token: string;
  createdBy?: IUser;
  createdAt?: string;
}

export const INTEGRATION = {
  CHAT_BOT: "CHAT_BOT",
};
