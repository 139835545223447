import { IQueueTimeReportServiceGetInfoResult } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class QueueTimeReportService extends BaseService<IQueueTimeReportServiceGetInfoResult> {
  getInfo(config?: AxiosRequestConfig) {
    return this.http.get<IQueueTimeReportServiceGetInfoResult>(
      `${this.route}`,
      config,
    );
  }
}

export default new QueueTimeReportService("reports/queue-time");
