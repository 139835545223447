import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import { FormAction } from "@/definitions";
import SurveyPage from "@/views/pages/survey/SurveyPage.vue";
import SurveyDetailPage from "@/views/pages/survey/SurveyDetailPage.vue";
import SurveyFormPage from "@/views/pages/survey/SurveyFormPage.vue";

const surveyRouter: RouteRecordRaw = {
  name: "Survey",
  path: "survey",
  children: [
    {
      name: "ListSurvey",
      path: "",
      component: SurveyPage,
    },
    {
      name: "ViewSurveyById",
      path: ":id",
      component: SurveyDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditSurveyById",
      path: ":id/edit",
      component: SurveyFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateSurvey",
      path: "create",
      component: SurveyFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};
export default surveyRouter;
