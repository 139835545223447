import { IUserLabel, IUser } from "@/definitions";

export interface IAgentProductivityReportFilter {
  user?: IUser | null;
  userLabel?: IUserLabel | null;
}
export interface IAgentProductivityReportState {
  filter: IAgentProductivityReportFilter;
}

export enum AgentProductivityReportMutations {
  SET_FILTER = "agentProductivityReport_setFilter",
  CLEAR_FILTER = "agentProductivityReport_clearFilter",
}

export enum AgentProductivityReportActions {
  SET_FILTER = "agentProductivityReport_setFilter",
  CLEAR_FILTER = "agentProductivityReport_clearFilter",
}

export enum AgentProductivityReportGetters {
  DEFAULT_FILTER = "agentProductivityReport_defaultFilter",
  FILTER = "agentProductivityReport_filter",
  ACTIVE_FILTERS = "agentProductivityReport_activeFilters",
}
