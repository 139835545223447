import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import WordPage from "@/views/pages/word/WordPage.vue";
import WordDetailPage from "@/views/pages/word/WordDetailPage.vue";
import WordFormPage from "@/views/pages/word/WordFormPage.vue";
import { FormAction } from "@/definitions";

const wordRouter: RouteRecordRaw = {
  name: "Word",
  path: "word",
  children: [
    {
      name: "ListWord",
      path: "",
      component: WordPage,
    },
    {
      name: "ViewWordById",
      path: ":id",
      component: WordDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditWordById",
      path: ":id/edit",
      component: WordFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateWord",
      path: "create",
      component: WordFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};

export default wordRouter;
