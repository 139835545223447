import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import { FormAction } from "@/definitions";
import LevelPage from "@/views/pages/level/LevelPage.vue";
import LevelDetailPage from "@/views/pages/level/LevelDetailPage.vue";
import LevelFormPage from "@/views/pages/level/LevelFormPage.vue";

const levelRouter: RouteRecordRaw = {
  name: "Level",
  path: "level",
  children: [
    {
      name: "ListLevel",
      path: "",
      component: LevelPage,
    },
    {
      name: "ViewLevelById",
      path: ":id",
      component: LevelDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditLevelById",
      path: ":id/edit",
      component: LevelFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateLevel",
      path: "create",
      component: LevelFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};
export default levelRouter;
