import { IConnectFlowAudio, IDefaultServiceResult } from "@/definitions";
import BaseService from "@/services/BaseService";

class ConnectFlowAudioService extends BaseService<IConnectFlowAudio> {
  activate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
  };
  inactivate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(
      `${this.route}/${id}/inactivate`,
    );
  };
}

export default new ConnectFlowAudioService("audios");
