import { ISurvey, IDefaultServiceResult } from "@/definitions";
import BaseService from "@/services/BaseService";

class SurveyService extends BaseService<ISurvey> {
  activate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
  };
  inactivate = (id: number | string) => {
    return this.http.put<IDefaultServiceResult>(
      `${this.route}/${id}/inactivate`,
    );
  };
}

export default new SurveyService("surveys");
