import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import { FormAction } from "@/definitions";
import SchedulePage from "@/views/pages/schedule/SchedulePage.vue";
import ScheduleDetailPage from "@/views/pages/schedule/ScheduleDetailPage.vue";
import ScheduleFormPage from "@/views/pages/schedule/ScheduleFormPage.vue";

const scheduleRouter: RouteRecordRaw = {
  name: "Schedule",
  path: "schedule",
  children: [
    {
      name: "ListSchedule",
      path: "",
      component: SchedulePage,
    },
    {
      name: "ViewScheduleById",
      path: ":id",
      component: ScheduleDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditScheduleById",
      path: ":id/edit",
      component: ScheduleFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateSchedule",
      path: "create",
      component: ScheduleFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};
export default scheduleRouter;
