import {
  CallServiceTimeRange,
  CallStatus,
  CallType,
  INumber,
  IUser,
  IUserLabel,
  IMultiSelectOption,
} from "@/definitions";

export interface ICallReportFilter {
  type: IMultiSelectOption<CallType> | null;
  status?: IMultiSelectOption<CallStatus> | null;
  user?: IUser | null;
  number?: INumber | null;
  userLabel?: IUserLabel | null;
  serviceTimeRange?: IMultiSelectOption<CallServiceTimeRange> | null;
}
export interface ICallReportState {
  filter: ICallReportFilter;
}

export enum CallReportMutations {
  SET_FILTER = "callReport_setFilter",
  CLEAR_FILTER = "callReport_clearFilter",
}

export enum CallReportActions {
  SET_FILTER = "callReport_setFilter",
  CLEAR_FILTER = "callReport_clearFilter",
}

export enum CallReportGetters {
  DEFAULT_FILTER = "callReport_defaultFilter",
  FILTER = "callReport_filter",
  ACTIVE_FILTERS = "callReport_activeFilters",
}
