import { Node } from "@vue-flow/core/dist/types/node";
import { Connection } from "@vue-flow/core/dist/types/connection";
import { IMultiSelectOption } from "@/definitions";

export enum NodeType {
  START = "nodeStart",
  TIME_VALIDATION = "timeValidation",
  OPEN_EDGE = "openEdge",
  PLAY_AUDIO = "playAudio",
  CLIENT_INFO = "clientInfo",
  TRANSFER_EXTENSION = "transferToExtension",
  TRANSFER_EXTERNAL = "transferToExternal",
  TRANSFER_QUEUE = "transferToQueue",
  TRANSFER_SURVEY = "transferToSurvey",
  HANG_UP = "hangUp",
  GO_TO = "goTo",
}

export enum EdgeType {
  NORMAL = "Normal",
  AWAITABLE = "Awaitable",
  CLIENT_INFO_EDGE = "ClientInfoEdge",
}

export interface IFlowNodeData {
  title?: string;
  description?: string;
  component: NodeType;
  related: IMultiSelectOption | null;
  goToNode?: string;
  textToAudio?: string;
  callTo?: string[];
  redirectType?: string;
  userInput?: boolean;
  whisper?: string;
  hasErrors: boolean;
  flowType?: string;
}

export interface IFlowNode extends Node {
  parentId: string;
  sourceHandle?: string;
  data: IFlowNodeData;
}

export interface IFlowConnectionData {
  component: EdgeType;
  uraOption: number | null;
  waitUser: boolean;
  timerWait?: number;
  hasErrors: boolean;
  flowType?: string;
}
export interface IFlowConnection extends Connection {
  id: string;
  label?: string;
  type?: string;
  data?: IFlowConnectionData;
  sourceHandle?: string;
  markerEnd?: string;
}
