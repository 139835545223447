import { IListable } from "@/definitions";

export interface ISurveyFilter {
  isActive: boolean | null;
}
export interface ISurveyState extends IListable {
  filter: ISurveyFilter;
}

export enum SurveyMutations {
  SET_SEARCH = "survey_setSearch",
  SET_SORT = "survey_setSort",
  SET_PAGE = "survey_setPage",
  SET_FILTER = "survey_setFilter",
  CLEAR_FILTER = "survey_clearFilter",
}

export enum SurveyActions {
  SET_SEARCH = "survey_setSearch",
  SET_SORT = "survey_setSort",
  SET_PAGE = "survey_setPage",
  SET_FILTER = "survey_setFilter",
  CLEAR_FILTER = "survey_clearFilter",
}

export enum SurveyGetters {
  SEARCH = "survey_search",
  SORT = "survey_sort",
  PAGE = "survey_page",
  FILTER = "survey_filter",
}
