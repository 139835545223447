import { ILevel } from "../core-typings/ILevel";

export interface IFilterState {
  level: ILevel | null;
  dateRange: Array<Date> | null;
}

export enum FilterMutations {
  SET_LEVEL = "filter_setLevel",
  SET_DATE_RANGE = "filter_setDateRange",
}

export enum FilterActions {
  SET_LEVEL = "filter_setLevel",
  SET_DATE_RANGE = "filter_setDateRange",
}

export enum FilterGetters {
  LEVEL = "filter_level",
  DATE_RANGE = "filter_dateRange",
}
