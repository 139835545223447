import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import UserLabelPage from "@/views/pages/userLabel/UserLabelPage.vue";
import UserLabelDetailPage from "@/views/pages/userLabel/UserLabelDetailPage.vue";
import UserLabelFormPage from "@/views/pages/userLabel/UserLabelFormPage.vue";
import { FormAction } from "@/definitions";

const userLabelRouter: RouteRecordRaw = {
  name: "UserLabel",
  path: "user-label",
  children: [
    {
      name: "ListUserLabel",
      path: "",
      component: UserLabelPage,
    },
    {
      name: "ViewUserLabelById",
      path: ":id",
      component: UserLabelDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
    {
      name: "EditUserLabelById",
      path: ":id/edit",
      component: UserLabelFormPage,
      beforeEnter: [idIsPositiveInteger],
      props: { action: FormAction.EDIT },
    },
    {
      name: "CreateUserLabel",
      path: "create",
      component: UserLabelFormPage,
      props: { action: FormAction.CREATE },
    },
  ],
};

export default userLabelRouter;
