import { ICall } from "@/definitions";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios/index";

class CallReportService extends BaseService<ICall> {
  getInfo<ICallsStats>(config?: AxiosRequestConfig) {
    return this.http.get<ICallsStats>(`${this.route}`, config);
  }
}

export default new CallReportService("reports/calls");
