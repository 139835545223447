/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IFilterItem,
  IAgentProductivityPerDayReportState,
  AgentProductivityPerDayReportMutations,
  AgentProductivityPerDayReportActions,
  AgentProductivityPerDayReportGetters,
  IAgentProductivityPerDayReportFilter,
} from "@/definitions";

const getDefaultState = () => {
  return {
    filter: {
      user: null,
      userLabel: null,
    },
  } as IAgentProductivityPerDayReportState;
};

const state: IAgentProductivityPerDayReportState = getDefaultState();

const mutations: MutationTree<IAgentProductivityPerDayReportState> = {
  [AgentProductivityPerDayReportMutations.SET_FILTER](
    state,
    payload: IAgentProductivityPerDayReportFilter,
  ) {
    state.filter.user = payload.user;
    state.filter.userLabel = payload.userLabel;
  },
  [AgentProductivityPerDayReportMutations.CLEAR_FILTER](state, field?: string) {
    switch (field) {
      case "user":
        state.filter.user = getDefaultState().filter.user;
        break;
      case "userLabel":
        state.filter.userLabel = getDefaultState().filter.userLabel;
        break;
      default:
        state.filter.user = getDefaultState().filter.user;
        state.filter.userLabel = getDefaultState().filter.userLabel;
    }
  },
};

const actions: ActionTree<IAgentProductivityPerDayReportState, any> = {
  [AgentProductivityPerDayReportActions.SET_FILTER]: (
    { commit },
    filter: IAgentProductivityPerDayReportFilter,
  ) => commit(AgentProductivityPerDayReportMutations.SET_FILTER, filter),
  [AgentProductivityPerDayReportActions.CLEAR_FILTER]: (
    { commit },
    filter: IAgentProductivityPerDayReportFilter,
  ) => commit(AgentProductivityPerDayReportMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<IAgentProductivityPerDayReportState, any> = {
  [AgentProductivityPerDayReportGetters.DEFAULT_FILTER]:
    (): IAgentProductivityPerDayReportFilter => getDefaultState().filter,
  [AgentProductivityPerDayReportGetters.FILTER]: (
    state,
  ): IAgentProductivityPerDayReportFilter => state.filter,
  [AgentProductivityPerDayReportGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    if (state.filter.user)
      filters.push({
        field: "user",
        value: state.filter.user.id,
        label: state.filter.user.name,
      });

    if (state.filter.userLabel)
      filters.push({
        field: "userLabel",
        value: state.filter.userLabel.id,
        label: state.filter.userLabel.label,
      });

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
