import { IDataItem, IUser, ISurveyQuestionAnswer } from "@/definitions";
import { ILevel } from "./ILevel";

export enum CallType {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
  INTERNAL = "INTERNAL",
}

export interface ICallTypeCount {
  [CallType.INBOUND]: number;
  [CallType.OUTBOUND]: number;
  [CallType.INTERNAL]: number;
}

export interface ICallTMCount {
  service: number;
  waiting: number;
}

export enum CallStatus {
  ON_PROGRESS = "ON_PROGRESS",
  ANSWERED = "ANSWERED",
  NO_ANSWER = "NO_ANSWER",
  BUSY = "BUSY",
  DITCHED = "DITCHED",
}

export enum CallStatusClassColor {
  ANSWERED = "bg-green-600",
  NO_ANSWER = "bg-red-500",
  BUSY = "bg-yellow-500",
  DITCHED = "bg-gray-600",
  ON_PROGRESS = "bg-gray-400",
}

export interface ICallStatusCount {
  [CallStatus.ANSWERED]: number;
  [CallStatus.NO_ANSWER]: number;
  [CallStatus.BUSY]: number;
  [CallStatus.DITCHED]: number;
}

export enum CallServiceTimeRange {
  BELOW_30S = "<30",
  BETWEEN_30S_AND_60S = "30-60",
  BETWEEN_61_AND_180S = "61-180",
  BETWEEN_181S_AND_300S = "181-300",
  BETWEEN_301S_AND_480S = "301-480",
  BETWEEN_481S_AND_600S = "481-600",
  ABOVE_600 = ">600",
}

export interface ICallServiceTimeRangeCount {
  [CallServiceTimeRange.BELOW_30S]: number;
  [CallServiceTimeRange.BETWEEN_30S_AND_60S]: number;
  [CallServiceTimeRange.BETWEEN_61_AND_180S]: number;
  [CallServiceTimeRange.BETWEEN_181S_AND_300S]: number;
  [CallServiceTimeRange.BETWEEN_301S_AND_480S]: number;
  [CallServiceTimeRange.BETWEEN_481S_AND_600S]: number;
  [CallServiceTimeRange.ABOVE_600]: number;
}

export interface ICall {
  id: number;
  level: ILevel;
  environmentId: string;
  type: CallType;
  startedAt: Date;
  endedAt?: Date;
  answeredAt?: Date;
  sourceNumber: string;
  sourceUserId?: string;
  sourceExtensionNumber?: string;
  destinationUser?: IUser;
  destinationNumber: string;
  destinationUserId?: string;
  destinationExtensionNumber?: string;
  media?: string;
  adId?: string;
  keyword?: string;
  audioFilePath?: string;
  externalId?: string;
  duration: number;
  billSec?: number;
  geolocation?: string;
  status: CallStatus;
  survey?: ICallSurvey;
  ura: ICallUraNavigationItem[];
  isFirstContact: boolean;
  createdAt?: string;
  updatedAt?: string;
  data: ICallData;
  words: ICallWord[];
  tags: ICallTag[];
  events: ICallEvent[];
}

export interface ICallSurvey {
  name: string;
  surveyQuestions: ISurveyQuestionAnswer[];
}

export interface ICallUraNavigationItem {
  node: string;
  digit: string;
}

export interface ICallEvent {
  id: number;
  callID: number;
  at: Date;
  type: CallType;
  status: CallStatus;
  audioFilePath?: string;
  sourceNumber: string;
  sourceUserId?: string;
  sourceExtensionNumber?: string;
  sourceUser?: IUser;
  destinationNumber: string;
  destinationUserId?: string;
  destinationExtensionNumber?: string;
  destinationUser?: IUser;
  externalId?: string;
  duration: number;
  geolocation?: string;
  ivrOption?: string;
  additionalInfo?: ICallData;
  peerIp?: string;
  rtpQosAll?: string;
  createdAt?: string;
  updatedAt?: string;
  call?: ICall;
}

export interface ICallData {
  callId: number;
  environmentId: number;
  startedAt: number;
  endedAt: number;
  duration: number;
  silence: number;
  agentStartedAt: number;
  agentEndedAt: number;
  agentSilenceTime: number;
  agentTalkTime: number;
  agentDuration: number;
  agentTalkPercentage: number;
  agentTalkOver: number;
  agentEmotion: string;
  agentStartEmotion: string;
  agentMiddleEmotion: string;
  agentEndEmotion: string;
  agentSentiment: string;
  agentStartSentiment: string;
  agentMiddleSentiment: string;
  agentEndSentiment: string;
  clientStartedAt: number;
  clientEndedAt: number;
  clientSilenceTime: number;
  clientTalkTime: number;
  clientDuration: number;
  clientTalkPercentage: number;
  clientTalkOver: number;
  clientEmotion: string;
  clientStartEmotion: string;
  clientMiddleEmotion: string;
  clientEndEmotion: string;
  clientSentiment: string;
  clientStartSentiment: string;
  clientMiddleSentiment: string;
  clientEndSentiment: string;
  failed: boolean;
  errorMessage: string;
}

export interface ICallTimelineItem {
  id: number;
  callId: number;
  environmentId: string;
  participant: string;
  language: string;
  transcript: string;
  startedAt: number;
  endedAt: number;
  words: string[];
  registeredWords?: ICallWord[];
  matchedWords: ICallWord[];
  insights: ICallInsight[];
}

export interface IWordsByPersona {
  agent: ICallWord[];
  client: ICallWord[];
}

export interface ICallWord {
  text: string;
  counter: number;
  registered?: boolean;
  selected?: boolean;
}

export interface ICallTag {
  id: number;
  environment_id: string;
  key: string;
  value: string;
}

export interface IInsightByPersona {
  agent: ICallInsight[];
  client: ICallInsight[];
}

export interface ICallInsight {
  id: number;
  environment_id: string;
  key: string;
  value: string;
}

export enum ParticipantType {
  All = "ALL",
  Client = "CLIENT",
  Agent = "AGENT",
  Uninformed = "UNINFORMED",
}

export interface ICallTimeOfDayCount {
  hour: number;
  weekDayIndex: number;
  value: number;
}
export interface ICallsStats {
  total: number;
  totalProcessed: number;
  type: ICallTypeCount;
  typePerDay: IDataItem<ICallTypeCount>[];
  tmPerDay: IDataItem<ICallTMCount>[];
  status: ICallStatusCount;
  statusPerDay: IDataItem<ICallStatusCount>[];
  media: IDataItem<number>[];
  serviceTimeRange: ICallServiceTimeRangeCount;
  timeOfDay: ICallTimeOfDayCount[];
  uniqueNumbers: number;
  averageSilencePercentage: number;
  averageServiceTime?: number;
  averageWaitingTime?: number;
}

export interface IUserAgentCallsStats extends ICallsStats {
  id: number;
  name: string;
  averageDuration: number;
  averageTimeAvailable?: number;
  averageTotalIdleTime?: number;
  averageIdleTime?: number;
}

export interface ICallsStatsPerDay extends ICallsStats {
  date: string;
  averageDuration: number;
  averageTimeAvailable?: number;
  averageTotalIdleTime?: number;
  averageIdleTime?: number;
}
