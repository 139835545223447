import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import CallPage from "@/views/pages/call/CallPage.vue";
import CallDetailPage from "@/views/pages/call/CallDetailPage.vue";

const levelRouter: RouteRecordRaw = {
  name: "Calls",
  path: "/calls",
  children: [
    {
      name: "CallList",
      path: ":tab?",
      component: CallPage,
    },
    {
      name: "ViewCallById",
      path: "player/:id",
      component: CallDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
  ],
};
export default levelRouter;
