/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IConnectFlowState,
  IConnectFlowFilter,
  ConnectFlowMutations,
  ConnectFlowActions,
  ConnectFlowGetters,
  ISortMenuItem,
  IFilterItem,
} from "@/definitions";
import { useI18n } from "vue-i18n";
import ConnectFlowSubModeStore from "@/store/modules/connectFlow.submodule.store";

const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      level: null,
      isActive: null,
    },
  } as IConnectFlowState;
};

const state: IConnectFlowState = getDefaultState();

const mutations: MutationTree<IConnectFlowState> = {
  [ConnectFlowMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [ConnectFlowMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [ConnectFlowMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [ConnectFlowMutations.SET_FILTER](state, payload: IConnectFlowFilter) {
    state.filter.isActive = payload.isActive;
  },
  [ConnectFlowMutations.CLEAR_FILTER](state, field?: string) {
    switch (field) {
      case "isActive":
        state.filter.isActive = getDefaultState().filter.isActive;
        break;
      default:
        state.filter.isActive = getDefaultState().filter.isActive;
    }
  },
};

const actions: ActionTree<IConnectFlowState, any> = {
  [ConnectFlowActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(ConnectFlowMutations.SET_SEARCH, search),
  [ConnectFlowActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(ConnectFlowMutations.SET_SORT, sort),
  [ConnectFlowActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(ConnectFlowMutations.SET_PAGE, page),
  [ConnectFlowActions.SET_FILTER]({ commit }, payload: IConnectFlowFilter) {
    commit(ConnectFlowMutations.SET_FILTER, payload);
  },
  [ConnectFlowActions.CLEAR_FILTER]({ commit }, field?: string) {
    commit(ConnectFlowMutations.CLEAR_FILTER, field);
  },
};

const getters: GetterTree<IConnectFlowState, any> = {
  [ConnectFlowGetters.SEARCH]: (state): string | null => state.search,
  [ConnectFlowGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [ConnectFlowGetters.PAGE]: (state): number => state.page,
  [ConnectFlowGetters.FILTER]: (state): IConnectFlowFilter => state.filter,
  [ConnectFlowGetters.DEFAULT_FILTER]: (): IConnectFlowFilter =>
    getDefaultState().filter,
  [ConnectFlowGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    const { t } = useI18n();
    if (state.filter.isActive) {
      filters.push({
        field: "isActive",
        value: state.filter.isActive,
        label: t(
          `connectFlow.filters.activeTag.isActive.${state.filter.isActive.toString()}`,
        ),
      });
    }

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  modules: {
    flow: ConnectFlowSubModeStore,
  },
};
