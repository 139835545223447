import { RouteRecordRaw } from "vue-router";
import { idIsPositiveInteger } from "@/router/routeGuards";
import MeetingPage from "@/views/pages/meeting/MeetingPage.vue";
import MeetingDetailPage from "@/views/pages/meeting/MeetingDetailPage.vue";

const levelRouter: RouteRecordRaw = {
  name: "Meetings",
  path: "/meetings",
  children: [
    {
      name: "MeetingList",
      path: ":tab?",
      component: MeetingPage,
    },
    {
      name: "ViewMeetingById",
      path: "player/:id",
      component: MeetingDetailPage,
      beforeEnter: [idIsPositiveInteger],
    },
  ],
};
export default levelRouter;
