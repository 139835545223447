export interface IQueueItem {
  numberFrom: string;
  numberTo: string;
  duration: number;
  queueId: string;
  queueName: string;
}

export interface IUserDetails {
  extension: string;
  user: string;
  userDevice: string;
  status: string;
  numberClient: string;
  quality: string;
  type: string;
  duration: number;
}

export enum UserDetailsStatus {
  CONNECTED = "CONNECTED",
  OFFLINE = "OFFLINE",
}
export interface IUserStatusCount {
  [UserDetailsStatus.CONNECTED]: number;
  [UserDetailsStatus.OFFLINE]: number;
}
