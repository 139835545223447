/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IFilterItem,
  IAgentProductivityReportState,
  AgentProductivityReportMutations,
  AgentProductivityReportActions,
  AgentProductivityReportGetters,
  IAgentProductivityReportFilter,
} from "@/definitions";

const getDefaultState = () => {
  return {
    filter: {
      user: null,
    },
  } as IAgentProductivityReportState;
};

const state: IAgentProductivityReportState = getDefaultState();

const mutations: MutationTree<IAgentProductivityReportState> = {
  [AgentProductivityReportMutations.SET_FILTER](
    state,
    payload: IAgentProductivityReportFilter,
  ) {
    state.filter.user = payload.user;
    state.filter.userLabel = payload.userLabel;
  },
  [AgentProductivityReportMutations.CLEAR_FILTER](state, field?: string) {
    switch (field) {
      case "user":
        state.filter.user = getDefaultState().filter.user;
        break;
      case "userLabel":
        state.filter.userLabel = getDefaultState().filter.userLabel;
        break;
      default:
        state.filter.user = getDefaultState().filter.user;
        state.filter.userLabel = getDefaultState().filter.userLabel;
    }
  },
};

const actions: ActionTree<IAgentProductivityReportState, any> = {
  [AgentProductivityReportActions.SET_FILTER]: (
    { commit },
    filter: IAgentProductivityReportFilter,
  ) => commit(AgentProductivityReportMutations.SET_FILTER, filter),
  [AgentProductivityReportActions.CLEAR_FILTER]: (
    { commit },
    filter: IAgentProductivityReportFilter,
  ) => commit(AgentProductivityReportMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<IAgentProductivityReportState, any> = {
  [AgentProductivityReportGetters.DEFAULT_FILTER]:
    (): IAgentProductivityReportFilter => getDefaultState().filter,
  [AgentProductivityReportGetters.FILTER]: (
    state,
  ): IAgentProductivityReportFilter => state.filter,
  [AgentProductivityReportGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    if (state.filter.user)
      filters.push({
        field: "user",
        value: state.filter.user.id,
        label: state.filter.user.name,
      });

    if (state.filter.userLabel)
      filters.push({
        field: "userLabel",
        value: state.filter.userLabel.id,
        label: state.filter.userLabel.label,
      });

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
